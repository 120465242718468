import React from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import ListSpecilaities from "config/constants/specialties.json";
import _ from "lodash";
import { Formik } from "formik";
import Textarea from "@material-tailwind/react/Textarea";
import { updateDoctorById } from "api/doctors";
import makeToast from "components/Snackbar";
const Data = ({ doctor, setDoctor }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">Informations générales</h2>
        </div>
      </CardHeader>
      <CardBody>
        {
          <Formik
            initialValues={{
              email: doctor?.email,
              phoneNumber: doctor?.phoneNumber,
              firstName: doctor?.firstName,
              lastName: doctor?.lastName,
              speciality: doctor?.speciality,
              sexe: doctor?.sexe,
              address: doctor?.address,
              city: doctor?.city,
              country: doctor?.country,
              postal_code: doctor?.postal_code,
              description: doctor?.description,
            }}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                const response = await updateDoctorById(values, doctor?._id);
                setDoctor(response);

                makeToast("success", "Vos modifications ont été enregistrées ");

                setLoading(false);
              } catch (err) {
                console.log("[error] Update doctor :", err);
              }
            }}
          >
            {(props) => {
              const {
                values,
                // errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-wrap mt-10">
                    <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                      <Input
                        type="email"
                        color="purple"
                        placeholder="Email"
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Numéro de téléphone"
                        name="phoneNumber"
                        value={values?.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Prénom"
                        name="firstName"
                        value={values?.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Nom"
                        name="lastName"
                        value={values?.lastName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                      <select
                        label="Select Version"
                        name="speciality"
                        onChange={handleChange}
                        value={values?.speciality}
                        className="form-select appearance-none  block w-full   py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-900 focus:outline-none"
                        aria-label="Specialitié"
                      >
                        {ListSpecilaities.map((el) => (
                          <option value={el.value}> {el.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                      <select
                        label="Select Version"
                        name="sexe"
                        onChange={handleChange}
                        value={values?.sexe}
                        className="form-select appearance-none  block w-full   py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-900 focus:outline-none"
                        aria-label="Sexe"
                      >
                        <option> Sexe</option>
                        <option value="women">Femme </option>
                        <option value="men"> Homme</option>
                      </select>
                    </div>
                  </div>

                  <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
                    INFORMATIONS DE CONTACT
                  </h6>
                  <div className="flex flex-wrap mt-10">
                    <div className="w-full lg:w-12/12 mb-10 font-light">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Adresse professionnelle"
                        name="address"
                        value={values?.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-4/12 pr-4 mb-10 font-light">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Ville"
                        name="city"
                        value={values?.city}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-4/12 px-4 mb-10 font-light">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="Pays"
                        name="country"
                        value={values?.country}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
                      <Input
                        type="text"
                        color="purple"
                        placeholder="code postal"
                        name="postal_code"
                        value={values?.postal_code}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
                    à propos de moi
                  </h6>
                  <div className="flex flex-wrap mt-10 font-light">
                    <Textarea
                      color="purple"
                      placeholder="Description"
                      value={values?.description}
                      name="description"
                      onChange={handleChange}
                    />
                  </div>
                  {loading ? (
                    <div className="flex justify-center items-center mt-5">
                      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-purple-500"></div>
                    </div>
                  ) : (
                    <Button
                      color="purple"
                      ripple="dark"
                      className="w-full mt-6"
                    >
                      <span className="py-2 text-md">Enregistrer</span>
                    </Button>
                  )}
                </form>
              );
            }}
          </Formik>
        }
      </CardBody>
    </Card>
  );
};

export default Data;
