import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import AddDoctor from "./AddDoctor";
import Image from "@material-tailwind/react/Image";
import { loadImage } from "api/document";
import { updateDoctorById } from "api/doctors";

import "../styles.scss";
import { NavbarInput } from "@material-tailwind/react";

export default function PageVisitsCard({
  specialist,
  page,
  setPage,
  totalPage,
  totalResult,
  RefreshList,
  search,
  setSearch,
}) {
  const [loading, setLoading] = React.useState();
  const [docotrs, setDoctor] = React.useState();
  const history = useHistory();
  const handleDisableAccount = async (e, id) => {
    try {
      setLoading(true);
      const response = await updateDoctorById(
        { accountActive: e.target.checked },
        id
      );
      setDoctor(docotrs.map((el) => (el._id == id ? response : el)));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!_.isEmpty(specialist)) setDoctor(specialist);
  }, [specialist]);

  return (
    <Card>
      <CardHeader color="blue" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">Médecins</h2>
          <div className="flex justify-center items-center gap-6">
            <NavbarInput
              placeholder="Recherche"
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
              value={search}
            />
            <AddDoctor RefreshList={RefreshList} />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="overflow-x-auto">
          {loading ? (
            <div className="flex justify-center items-center w-full ">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
            </div>
          ) : (
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Nom
                  </th>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Email
                  </th>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Spécialité
                  </th>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Status
                  </th>
                  <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                    Activer / Désactiver
                  </th>
                </tr>
              </thead>

              <tbody>
                {_.isEmpty(docotrs) ? (
                  <></>
                ) : (
                  docotrs.map((el) => {
                    return (
                      <tr
                        onClick={() => history.push(`/doctor/${el._id}`)}
                        className="cursor-pointer"
                      >
                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left flex items-center gap-4  ">
                          <div className="w-10">
                            {el.business_photo ? (
                              <Image
                                src={loadImage(
                                  el._id,
                                  "profile",
                                  "thumbnail",
                                  el.business_photo
                                )}
                                rounded
                              />
                            ) : (
                              <div className="w-10 h-10 bg-blue-500 text-white rounded-full flex justify-center items-center font-bold uppercase">
                                {el.firstName.charAt(0) +
                                  " " +
                                  el.lastName.charAt(0)}
                              </div>
                            )}
                          </div>
                          <span className="font-bold uppercase">
                            {el.firstName + " " + el.lastName}
                          </span>
                        </th>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          {el.email}
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          {el.speciality}
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          {el.accountVerified ? (
                            <div className="w-3 h-3  rounded-full bg-green-500" />
                          ) : (
                            <div className="w-3 h-3  rounded-full bg-red-500" />
                          )}
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          <label
                            class="switch"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              checked={el.accountActive ? true : false}
                              onChange={(e) => handleDisableAccount(e, el._id)}
                            />
                            <span class="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </div>
      </CardBody>
      <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="flex-1 flex justify-between sm:hidden">
          <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            Previous{" "}
          </span>
          <span class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            Next{" "}
          </span>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p class="text-sm text-gray-700">
              Page N°<span class="font-medium">{page}</span>/
              <span class="font-medium">{totalPage}</span>
            </p>
          </div>
          <div>
            <nav
              class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <span
                class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page - 1)}
              >
                <span class="sr-only">Previous</span>
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              {Array.from({ length: totalPage }, (v, i) => i).map((el, i) => (
                <span
                  aria-current="page"
                  onClick={() => setPage(i + 1)}
                  class="cursor-pointer z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {i + 1}
                </span>
              ))}

              <span
                class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page + 1)}
              >
                <span class="sr-only">Next</span>
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </nav>
          </div>
        </div>
      </div>
    </Card>
  );
}
