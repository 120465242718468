import React from "react";
import Card from "@material-tailwind/react/Card";
import _ from "lodash";
import { CardBody, CardHeader } from "@material-tailwind/react";
import ModalTarif from "../Modals/Tarif";

const Tarif = ({ doctor, setDoctor }) => {
  return (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">TARIFS & PAIEMENTS</h2>
          <ModalTarif doctor={doctor} setDoctor={setDoctor} />
        </div>
      </CardHeader>
      <CardBody>
        <div className="consultationsDetails">
          <ul>
            {doctor?.pricing !== undefined && (
              <li>
                <div className="grid grid-cols-4  leading-4">
                  <div className="col-span-3">Consultation normal</div>
                  <div className="flex justify-end">
                    {doctor.pricing} {doctor.currency}
                  </div>
                </div>
              </li>
            )}
            {doctor?.pricingNight !== undefined && (
              <li>
                <div className="grid grid-cols-4  leading-4">
                  <div className="col-span-3">Consultation pendant la nuit</div>
                  <div className="flex justify-end">
                    {doctor.pricingNight} {doctor.currency}
                  </div>
                </div>
              </li>
            )}
            {doctor?.pricingWeekEnd !== undefined && (
              <li>
                <div className="grid grid-cols-4  leading-4">
                  <div className="col-span-3">
                    Consultation pendant le weekend et jours fériés
                  </div>
                  <div className="flex justify-end">
                    {doctor.pricingWeekEnd} {doctor.currency}
                  </div>
                </div>
              </li>
            )}
            {!_.isEmpty(doctor?.otherPrice) &&
              doctor?.otherPrice?.map((el) => (
                <li>
                  <div className="grid grid-cols-4  leading-4">
                    <div className="col-span-3">{el.tarifLabel}</div>
                    <div className="flex justify-end">
                      {el.price} {doctor.currency}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default Tarif;
