import React from "react";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import Palier from "pages/Palier";
import { JouralIns } from "pages/JouralIns";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "components/Sidebar";
import Footer from "components/Footer";
import DoctorDetails from "pages/DoctorDetails";

const index = ({ status }) => {
  return (
    <BrowserRouter>
      <Switch>
        {status === "GUEST" ? (
          <>
            <Route path="/" component={Login} />
            <Redirect from="*" to="/" />
          </>
        ) : (
          <>
            <Sidebar />
            <div className="md:ml-64">
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/doctors" component={Dashboard} />
                <Route exact path="/palier" component={Palier} />
                <Route exact path="/journauxIns" component={JouralIns} />
                <Route
                  exact
                  path="/doctor/:doctorId"
                  component={DoctorDetails}
                />
                <Redirect from="*" to="/" />
              </Switch>
              <Footer />
            </div>
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default index;
