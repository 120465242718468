import React from "react";
import _ from "lodash";
import moment from "moment";
import AddBillModal from "../Modals/AddBillModal";
import Card from "@material-tailwind/react/Card";
import Icon from "@material-tailwind/react/Icon";
import DownloadFile from "assets/download-files.jpg";
import { CardBody, CardHeader } from "@material-tailwind/react";
import { loadBill } from "api/document";
import { getAllPalier } from "api/palier";

// import timeZone from "./timeZone";
import "moment/locale/fr";
moment.locale("fr");
// moment.utc(timeZone);

const Facture = ({ doctor, setDoctor }) => {
  const [listePalier, setListePalier] = React.useState([]);
  const [bills, setBills] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalResult, setTotalResult] = React.useState(0);
  const [limit] = React.useState(10);

  const _getAllPalier = async () => {
    try {
      const response = await getAllPalier();
      setListePalier(response?.palier);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    _getAllPalier();
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(doctor?.katomiTNBilled)) {
      setTotalResult(doctor.katomiTNBilled.length);
      setTotalPage(Math.ceil(doctor.katomiTNBilled.length / limit));
      setBills(
        doctor.katomiTNBilled
          .sort((a, b) => +new Date(b.billableAt) - +new Date(a.billableAt))
          .slice((page - 1) * limit, (page - 1) * limit + limit)
      );
    }
  }, [page, doctor]);

  return (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl ">Factures</h2>
          <AddBillModal doctor={doctor} setDoctor={setDoctor} />
        </div>
      </CardHeader>
      <CardBody>
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Référence
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Date
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Facture
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Palier
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Montant à payer TTC
              </th>
              <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                Statut
              </th>
            </tr>
          </thead>

          <tbody>
            {!_.isEmpty(bills) &&
              bills.map((el) => {
                return (
                  <tr>
                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left ">
                      {el?.reference ? el?.reference : el?._id}
                    </th>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      {moment(el?.billableAt).format("LLLL")}
                    </td>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      <a
                        href={loadBill(doctor._id, el.bill)}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        {/* <Icon name="description" size="2xl" /> */}
                        <img
                          src={DownloadFile}
                          alt="telechager"
                          width={30}
                          height={30}
                        />
                      </a>
                    </td>
                    <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                      {listePalier.find((elm) => elm._id == el?.palier)?.name}
                    </th>
                    <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      {el?.price} TND
                    </td>
                    <td
                      className={`border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left ${
                        el?.billedAt ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {el?.billedAt ? "Payée" : "En attente"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </CardBody>
      <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="flex-1 flex justify-between sm:hidden">
          <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            Previous{" "}
          </span>
          <span class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            {" "}
            Next{" "}
          </span>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p class="text-sm text-gray-700">
              Page N°<span class="font-medium">{page}</span>/
              <span class="font-medium">{totalPage}</span>
            </p>
          </div>
          <div>
            <nav
              class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <span
                class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page - 1)}
              >
                <span class="sr-only">Previous</span>
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              {Array.from({ length: totalPage }, (v, i) => i).map((el, i) => (
                <span
                  aria-current="page"
                  onClick={() => setPage(i + 1)}
                  class="cursor-pointer z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {i + 1}
                </span>
              ))}

              <span
                class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setPage(page + 1)}
              >
                <span class="sr-only">Next</span>
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </nav>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Facture;
