import axios from "axios";

import { LOGIN_URL, VERIFY_URL, LOGOUT_URL } from "../config/api";

export async function login(credentials) {
  try {
    const res = await axios.post(LOGIN_URL, credentials, {
      withCredentials: true,
    });
    if (res.data.user.token) {
      localStorage.setItem("token", res.data.user.token);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function verify() {
  try {
    const res = await axios.create().get(VERIFY_URL, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function logout() {
  try {
    const res = await axios.post(
      LOGOUT_URL,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
