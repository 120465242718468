import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import Image from "@material-tailwind/react/Image";
import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import ProfilePicture from "assets/img/Doctor-icon.jpg";
import { useAtom } from "jotai";
import { userAtom } from "state/user";
import { logout } from "api/auth";
import { docctorAtom as DT } from "state/doctor";
import { getAllPalier } from "api/palier";
import PalierHistory from "./HistoryPalierModal";
export default function AdminNavbar({ showSidebar, setShowSidebar }) {
  const [showHistoryModal, setShowHistoryModal] = React.useState(false);
  const [palier, setPalier] = React.useState([]);
  const [, setUser] = useAtom(userAtom);
  const [doctorAtom] = useAtom(DT);
  const location = useLocation().pathname;
  const _logout = () => {
    logout();
    setUser();
  };

  const _getAllPalier = async () => {
    try {
      const response = await getAllPalier();
      setPalier(response?.palier);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    _getAllPalier();
  }, []);

  return (
    <nav className="bg-light-blue-500 md:ml-64 py-6 px-3">
      <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
        <div className="md:hidden">
          <Button
            color="transparent"
            buttonType="link"
            size="lg"
            iconOnly
            rounded
            ripple="light"
            onClick={() => setShowSidebar("left-0")}
          >
            <Icon name="menu" size="2xl" color="white" />
          </Button>
          <div
            className={`absolute top-2 md:hidden ${
              showSidebar === "left-0" ? "left-64" : "-left-64"
            } z-50 transition-all duration-300`}
          >
            <Button
              color="transparent"
              buttonType="link"
              size="lg"
              iconOnly
              rounded
              ripple="light"
              onClick={() => setShowSidebar("-left-64")}
            >
              <Icon name="close" size="2xl" color="white" />
            </Button>
          </div>
        </div>

        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col gap-3">
            {location === "/" ? (
              <h4 className="uppercase text-white text-base tracking-wider mt-1 font-bold">
                Accueil
              </h4>
            ) : location.toUpperCase().split("/")[1] === "DOCTOR" ? (
              <>
                <h4 className="uppercase text-white text-base tracking-wider mt-1 font-bold">
                  Nom et prénom :
                  <span className="text-sm">
                    {` ${doctorAtom?.firstName} ${doctorAtom?.lastName}`}
                  </span>
                </h4>
                <h4 className="uppercase text-white text-base tracking-wider mt-1 font-bold">
                  <span className="text-sm">Email : {doctorAtom?.email}</span>
                </h4>
                {doctorAtom?.katomiTNPalier && (
                  <h4 className="uppercase text-white text-base tracking-wider mt-1 font-bold">
                    <span className="text-sm flex items-center gap-2">
                      Palier en cours :{" "}
                      {/* {doctorAtom?.katomiTNBilled?.sort(
                        (a, b) =>
                          +new Date(a.billableAt) - +new Date(b.billableAt)
                      )[0]?.palier != doctorAtom?.katomiTNPalier
                        ? palier?.find(
                            (elm) => elm._id == doctorAtom?.katomiTNPalier
                          )?.name
                        : ""} */}
                      {doctorAtom?.katomiTNBilled?.sort(
                        (a, b) =>
                          +new Date(b.billableAt) - +new Date(a.billableAt)
                      )[0]?.palier == doctorAtom?.katomiTNPalier ? (
                        palier?.find(
                          (elm) => elm._id == doctorAtom?.katomiTNPalier
                        )?.name
                      ) : (
                        <span>
                          {doctorAtom?.katomiTNBilled?.sort(
                            (a, b) =>
                              +new Date(a.billableAt) - +new Date(b.billableAt)
                          )[0]?.palier &&
                            palier?.find(
                              (elm) =>
                                elm._id ==
                                doctorAtom?.katomiTNBilled?.sort(
                                  (a, b) =>
                                    +new Date(b.billableAt) -
                                    +new Date(a.billableAt)
                                )[0]?.palier
                            )?.name}{" "}
                          {palier?.find(
                            (elm) => elm._id == doctorAtom?.katomiTNPalier
                          )?.name &&
                            " ==> " +
                              palier?.find(
                                (elm) => elm._id == doctorAtom?.katomiTNPalier
                              )?.name}
                        </span>
                      )}
                    </span>
                    {!_.isEmpty(doctorAtom?.katomiTNPalierUpdateHistory) && (
                      <span className="flex gap-2 mt-4">
                        <span>Historique de palier</span>
                        <PalierHistory doctor={doctorAtom} paliers={palier} />
                      </span>
                      // <span
                      //   className="cursor-pointer y-full flex items-center"
                      //   onClick={() => setShowHistoryModal(true)}
                      // >
                      //   <Icon name="list_alt" size="2xl" />
                      // </span>
                    )}
                  </h4>
                )}
                {!_.isEmpty(
                  doctorAtom?.extensions?.filter((el) => el.active)
                ) && (
                  <h4 className="uppercase text-white text-base tracking-wider mt-1 font-bold">
                    <span className="text-sm">
                      Extensions actives :{" "}
                      {doctorAtom?.extensions
                        ?.filter((el) => el.active)
                        .map((el) => (
                          <span className="ml-3">{el.name}</span>
                        ))}
                    </span>
                  </h4>
                )}
              </>
            ) : location.toUpperCase().split("/")[1] ===
              "journauxIns".toUpperCase() ? (
              <h4 className="uppercase text-white text-base tracking-wider mt-1 font-bold">
                {"journaux Ins".toUpperCase()}
              </h4>
            ) : (
              <h4 className="uppercase text-white text-base tracking-wider mt-1 font-bold">
                {location.toUpperCase().split("/")[1]}
              </h4>
            )}
          </div>
          {/* : location.toUpperCase().replace("/", "")} */}

          <div className="flex">
            <div className="-mr-4 ml-6">
              <Dropdown
                color="transparent"
                buttonText={
                  <div className="w-12">
                    <Image src={ProfilePicture} rounded />
                  </div>
                }
                rounded
                style={{
                  padding: 0,
                  color: "transparent",
                }}
              >
                <DropdownItem color="lightBlue" onClick={_logout}>
                  Déconnexion
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
