import React, { useState } from "react";
import TimePicker from "react-time-picker";
import classNames from "classnames";
/** API & HELPER */
import { setAgendaConfiguration } from "api/doctors";
/** COMPONENTS */
import makeToast from "components/Snackbar";
import { Button } from "@material-tailwind/react";

const DAYS_IN_WEEK = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const duree = [
  { label: "15 min", value: "15" },
  { label: "30 min", value: "30" },
  { label: "45 min", value: "45" },
  { label: "1h", value: "60" },
];

const Acte = (props) => {
  const { doctor, setDoctor, selectedWorkLocation } = props;
  const [selectedInput, setSelectedInput] = useState("");
  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const [officeWorkingHours, setOfficeWorkingHours] = useState({
    start: "09:00",
    end: "18:00",
  });
  const [actDuration, setActDuration] = useState("30 min");
  const [temp, setTemp] = useState([
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ]);

  const submitTimeSlots = async () => {
    try {
      setLoadingBtn(true);
      await setAgendaConfiguration(
        {
          config: [
            {
              start: officeWorkingHours.start,
              end: officeWorkingHours.end,
              days: temp,
            },
          ],
          type: "act",
          timeSlotDuration: actDuration.slice(0.2),
          location: selectedWorkLocation,
          agendaId: doctor.agendaId,
          timeZone: doctor.timeZone,
        },
        doctor._id
      );
      makeToast(
        "success",
        "La configuration de votre agenda a été bien mise à jour"
      );
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      makeToast(
        "error",
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard"
      );
      console.error(error);
    }
  };

  return (
    <>
      <div className="workingDays flex justify-center items-center flex-wrap  gap-4 pt-10">
        {DAYS_IN_WEEK.map((day) => {
          return (
            <div
              className={classNames(
                "day flex justify-center items-center cursor-pointer",
                temp.includes(day) ? "active-box" : "disabled-box"
              )}
              onClick={() =>
                setTemp(
                  temp.includes(day)
                    ? temp.filter((e) => e !== day)
                    : [...temp, day]
                )
              }
            >
              <p>{day}</p>
            </div>
          );
        })}
        <Button
          status="secondary"
          width="222px"
          height="40px"
          onClick={() => setTemp(DAYS_IN_WEEK)}
        >
          Réinitialiser la semaine
        </Button>
      </div>
      <div className="plageHoraire flex justify-center items-center gap-x-2">
        <p>Créneaux pour mes actes :</p>
        <div className="flex justify-center items-center">
          <div
            className="flex justify-center items-center sub-values"
            onClick={(e) => {
              setSelectedInput("TemperatureTime");
            }}
          >
            {selectedInput === "TemperatureTime" ? (
              <TimePicker
                onChange={(e) => {
                  if (e !== null)
                    setOfficeWorkingHours({
                      ...officeWorkingHours,
                      start: e,
                    });
                }}
                format="HH:mm"
                value={officeWorkingHours.start}
                onClockClose={() => {
                  setSelectedInput("");
                }}
                onClockOpen={() => {}}
              />
            ) : (
              <p className="value flex justify-center items-center cursor-pointer">
                {officeWorkingHours.start !== 0
                  ? officeWorkingHours.start
                  : "00:00"}
              </p>
            )}
          </div>
          <p>à :</p>
          <div
            className="flex justify-center items-center sub-values"
            onClick={(e) => {
              setSelectedInput("TemperatureTime");
            }}
          >
            {selectedInput === "TemperatureTime" ? (
              <TimePicker
                onChange={(e) => {
                  if (e !== null)
                    setOfficeWorkingHours({
                      ...officeWorkingHours,
                      end: e,
                    });
                }}
                format="HH:mm"
                value={officeWorkingHours.end}
                onClockClose={() => {
                  setSelectedInput("");
                }}
                onClockOpen={() => {}}
              />
            ) : (
              <p className="value flex justify-center items-center cursor-pointer">
                {officeWorkingHours.end !== 0
                  ? officeWorkingHours.end
                  : "00:00"}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center items-center w-full my-5">
        <p>Les Actes durent :</p>
        <select
          options={duree}
          text="30 min"
          className="ml-4 cursor-pointer"
          name="morning"
          value={actDuration}
          onChange={(e) => {
            setActDuration(e.target.value);
          }}
        >
          {duree.map((el) => (
            <option value={el.value}>{el.label}</option>
          ))}
        </select>
      </div>

      <div className="flex justify-center items-center my-5">
        <Button
          status="primary"
          width="170px"
          height="50px"
          onClick={submitTimeSlots}
          disabled={loadingBtn}
        >
          <div className="relative">
            {loadingBtn && (
              <div className="absolute h-full w-full flex justify-center items-center">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            )}
            Enregistrer
          </div>
        </Button>
      </div>
    </>
  );
};

export default Acte;
