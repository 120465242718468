import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { addcardProToDoctor } from "api/document";
import makeToast from "components/Snackbar";
import { Button, Icon } from "@material-tailwind/react";

const ModifyProCard = ({ doctor, setDoctor, cardProURI }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [file, setFile] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    },
  });
  const onSubmit = async () => {
    const response = await addcardProToDoctor({
      file,
      doctorId: doctor?._id,
    });
    if (response.doctor) {
      makeToast("success", "Carte professionnelle ajoutée avec succès.");
      setInterval(() => {}, 2000);
      setDoctor({
        ...doctor,
        proCard: response.doctor.proCard,
      });
      setLoadingBtn(false);
      setShowModal(false);
    }
    setLoadingBtn(false);
  };
  return (
    <>
      <div
        className="absolute inset-0 cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        {doctor?.proCard ? (
          <img
            src={cardProURI}
            alt="proCard"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="flex justify-center items-center h-full">
            <Icon name="cloud_upload" size="7xl" />
          </div>
        )}
      </div>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">
                    carte professionnelle
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <>
                    <section className="container cursor-pointer">
                      <div
                        {...getRootProps({
                          className:
                            "dropzone flex justify-center items-center",
                        })}
                      >
                        <input {...getInputProps()} />
                        <p>
                          Glissez ou déposez votre carte professionnelle ou
                          cliquez ici.
                        </p>
                      </div>
                      <aside className="thumbsContainer gap-4 mt-4 ml-4 mb-4">
                        {file.preview && (
                          <img
                            src={file.preview}
                            alt="proCard"
                            className=" max-h-96"
                          />
                        )}
                      </aside>
                    </section>
                    <div className="w-full mt-4">
                      <Button
                        status="secondary"
                        width="100px"
                        height="50px"
                        className="float-left mb-4 ml-4 btn-delete"
                        onClick={() => setShowModal(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        status="primary"
                        className="float-right flex justify-center items-center mb-4 mr-4"
                        width="200px"
                        height="50px"
                        type="submit"
                        onClick={() => {
                          setLoadingBtn(true);
                          onSubmit();
                        }}
                      >
                        {loadingBtn ? (
                          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                        ) : (
                          "Enregistrer"
                        )}
                      </Button>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModifyProCard;
