import React from "react";
import DoctorsList from "components/DoctorsList";
import { getAllDoctors } from "api/doctors";

export default function Dashboard() {
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalResult, setTotalResult] = React.useState(0);
  const [limit] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [specialist, setSpecialist] = React.useState([]);

  const _getSpecialist = async (page) => {
    try {
      setLoading(true);
      const response = await getAllDoctors(page, limit, { name: search });
      setSpecialist(response?.doctors?.docs);
      setTotalResult(response?.doctors?.totalDocs);
      setTotalPage(response?.doctors?.totalPages);
      setPage(response?.doctors?.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error Fetch specialist :", error);
    }
  };

  React.useEffect(() => {
    _getSpecialist(page);
  }, [page, search]);

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
          <DoctorsList
            specialist={specialist}
            page={page}
            search={search}
            setSearch={setSearch}
            setPage={setPage}
            totalPage={totalPage}
            totalResult={totalResult}
            RefreshList={() => _getSpecialist(page)}
          />
        </div>
      </div>
    </>
  );
}
