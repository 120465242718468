import makeToast from "components/Snackbar";
import _ from "lodash";
import React from "react";
import { FieldArray, Formik } from "formik";
import currencyList from "config/constants/currency";
import Button from "@material-tailwind/react/Button";
import { Input } from "@material-tailwind/react";
import { updateDoctorById } from "api/doctors";
export default function Tarif({ doctor, setDoctor }) {
  const [showModal, setShowModal] = React.useState(false);

  const OtherConsultationPrice = {
    tarifLabel: "",
    price: "",
  };

  return (
    <>
      <h2
        className="text-white text-md cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        Modifier
      </h2>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">
                    Modifier
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <Formik
                    initialValues={{
                      currency: doctor?.currency || "EUR",
                      otherPrice: !_.isEmpty(doctor?.otherPrice)
                        ? doctor?.otherPrice
                        : [
                            {
                              tarifLabel: "",
                              price: "",
                            },
                          ],
                      pricingNight:
                        doctor?.pricingNight !== undefined
                          ? doctor?.pricingNight
                          : 0,
                      pricingWeekEnd:
                        doctor?.pricingWeekEnd !== undefined
                          ? doctor?.pricingWeekEnd
                          : 0,
                      pricing:
                        doctor?.pricing !== undefined ? doctor?.pricing : 0,
                    }}
                    onSubmit={async (values) => {
                      try {
                        values.otherPrice = values.otherPrice.filter(
                          (el) => !!el.tarifLabel && !!el.price
                        );
                        const newUser = await updateDoctorById(
                          values,
                          doctor?._id
                        );
                        const {
                          pricing,
                          pricingNight,
                          pricingWeekEnd,
                          otherPrice,
                          currency,
                        } = newUser;

                        setDoctor({
                          ...doctor,
                          currency,
                          pricing,
                          pricingNight,
                          pricingWeekEnd,
                          otherPrice,
                        });
                        setShowModal(!showModal);
                        if (newUser._id) {
                          makeToast("success", "Tarifs modifiés avec succès.");
                        } else {
                          makeToast("error", "Modification tarifs a échouhé.");
                        }
                      } catch (err) {
                        console.log("[error] add Patient -on Submit :", err);
                      }
                    }}
                    // validationSchema={tarifSchema}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="flex-auto pb-7 px-8 min-w-ful"
                        >
                          <h3 className="text-black">Devise</h3>
                          <div className="mt-2">
                            <select
                              id="currency"
                              name="currency"
                              options={currencyList.map((el) => ({
                                label: `${el.code} - ${el.name} `,
                                value: el.code,
                              }))}
                              placeholder="Devise"
                              label="Devise"
                              value={values.currency}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="mb-5 form-select appearance-none  block w-full  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              aria-label="currency"
                            >
                              {currencyList.map((el) => (
                                <option
                                  value={el.code}
                                >{`${el.code} - ${el.name} `}</option>
                              ))}
                            </select>
                            <h3 className="mb-3 text-black">
                              Prix consultation
                            </h3>
                            <Input
                              id="pricing"
                              placeholder="Prix consultation normal"
                              labelText="Prix consultation normal"
                              type="text"
                              className=""
                              value={values.pricing}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />
                            <div className="mt-8" />
                            <Input
                              id="pricingNight"
                              placeholder="Prix consultation du nuit"
                              labelText="Prix consultation du nuit"
                              type="text"
                              className=""
                              //error={values.pricingNight}
                              value={values.pricingNight}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />
                            <div className="mt-8" />

                            <Input
                              id="pricingWeekEnd"
                              placeholder="Prix consultation pendant le weekEnd"
                              labelText="Prix consultation pendant le weekEnd"
                              type="text"
                              className=""
                              //error={values.pricingWeekEnd}
                              value={values.pricingWeekEnd}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />
                            <div className="mt-8" />
                          </div>
                          <h3 className="my-7 text-black">
                            Autre type de consultation
                          </h3>
                          <FieldArray name="otherPrice">
                            {({ push, remove }) => (
                              <>
                                {values.otherPrice.map((element, index) => (
                                  <div
                                    key={`otherPrice-${index}`}
                                    className="flex"
                                  >
                                    <div className="w-9/12">
                                      <Input
                                        id={`otherPrice[${index}].tarifLabel`}
                                        placeholder="Type de consultation"
                                        labelText="Type de consultation"
                                        type="text"
                                        value={
                                          values.otherPrice[index].tarifLabel
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <div className="mt-3" />

                                      <Input
                                        id={`otherPrice[${index}].price`}
                                        placeholder="Prix consultation "
                                        labelText="Prix consultation "
                                        type="text"
                                        value={values.otherPrice[index].price}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                    </div>
                                    <div className="flex justify-center items-center w-3/12 space-x-1">
                                      {values.otherPrice.length > 1 && (
                                        <Button
                                          disabled={isSubmitting}
                                          onClick={() => remove(index)}
                                          width={"40px"}
                                          height={"40px"}
                                        >
                                          -
                                        </Button>
                                      )}
                                      {values.otherPrice.length ==
                                        index + 1 && (
                                        <Button
                                          disabled={isSubmitting}
                                          type="button"
                                          onClick={() =>
                                            push(OtherConsultationPrice)
                                          }
                                          width={"40px"}
                                          height={"40px"}
                                        >
                                          +
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </FieldArray>
                          <div className="mb-4 flex justify-around mt-10">
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className="btn-delete"
                              onClick={() => setShowModal(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className=""
                            >
                              Enregistrer
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
