import { useState } from "react";
import moment from "moment";
import Button from "@material-tailwind/react/Button";

import _ from "lodash";

import { Icon } from "@material-tailwind/react";

export default function PalierHistory({ doctor, paliers }) {
  const [showModal, setShowModal] = useState(false);
  const handleOpen = (value) => setShowModal(!showModal);

  return (
    <>
      <span
        className="cursor-pointer y-full flex items-center"
        onClick={handleOpen}
      >
        <Icon name="list_alt" size="2xl" />
      </span>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto  overflow-y-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 gap-10 border-b border-solid rounded-t">
                  <h3 className="text-2xl font-semibold  text-black mt-5">
                    Historique des changements de paliers
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" h-6 w-6 text-2xl block outline-none focus:outline-none text-gray-500">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto ">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Depuis
                        </th>
                        <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Vers
                        </th>
                        <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Date
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {doctor.katomiTNPalierUpdateHistory.map((palier) => {
                        return (
                          <tr>
                            <th className="border-b text-black border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left flex items-center gap-4  ">
                              {
                                paliers.find((el) => el._id == palier?.from)
                                  ?.name
                              }
                            </th>
                            <td className="border-b text-black border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                              {paliers.find((el) => el._id == palier?.to)?.name}
                            </td>
                            <td className="border-b text-black border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                              {moment(palier?.date).format("DD/MM/YYYY")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowModal(false);
            }}
          ></div>
        </>
      ) : null}
    </>
  );
}
