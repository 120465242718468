import { useState } from "react";
import Button from "@material-tailwind/react/Button";
import { Formik } from "formik";
import _ from "lodash";
import ListSpecilaities from "config/constants/specialties.json";
import CountryWithPhoneSuffix from "config/constants/pays.json";
import InputIcon from "@material-tailwind/react/InputIcon";
// import { Select, Option } from "@material-tailwind/react";
import { addDoctor } from "api/doctors";
import makeToast from "components/Snackbar";

export default function AddDoctor({ RefreshList }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = (value) => setShowModal(!showModal);

  return (
    <>
      <Button
        onClick={handleOpen}
        color="transparent"
        buttonType="link"
        size="lg"
        style={{ padding: 0 }}
      >
        + Ajouter
      </Button>

      {showModal ? (
        <>
          <Formik
            initialValues={{
              fname: "",
              lname: "",
              email: "",
              password: "Katomi2022**",
              confirmPassword: "",
              industry_id: "1",
              address: "",
              postal_code: "",
              country: "",
              city: "",
              State: "Tunisia",
              phone: "",
              speciality: "",
              numCom: "",
              constCGU: false,
              cguModalIsOpen: false,
              sexe: "men",
              phoneSuffix: "",
              addressDetails: "",
            }}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                values.phoneSuffix = CountryWithPhoneSuffix.find(
                  (el) => el.name === values.country
                )?.dial_code;
                const response = await addDoctor(values);

                if (response) {
                  if (response.status === "existEmail") {
                    setLoading(false);
                    return makeToast(
                      "warning",
                      "Un médecin existe avec ce mail"
                    );
                  }
                  if (response.status === "existPhone") {
                    setLoading(false);
                    return makeToast(
                      "warning",
                      "Un médecin existe avec ce numéro de téléphone"
                    );
                  }
                  makeToast(
                    "success",
                    "L'inscription a été effectuée avec succès"
                  );
                  RefreshList();
                  setLoading(false);
                  setShowModal(false);
                } else {
                  makeToast("warning", "Un médecin existe avec ce mail");
                }
              } catch (err) {
                setLoading(false);

                console.log("[error] add Patient -on Submit :", err);
              }
            }}
            // validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                // errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = props;

              return (
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                  <div
                    className="relative my-6 mx-auto max-w-3xl overflow-y-auto  h-90 rounded-lg shadow-lg"
                    style={{ width: "700px" }}
                  >
                    {/*content*/}
                    <div className="border-0  ounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                        <h3 className="text-3xl font-semibold  text-black">
                          Nouveau médecin
                        </h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div
                        className="relative p-6 flex-auto"
                        style={{ paddingLeft: "80px", paddingRight: "80px" }}
                      >
                        <form onSubmit={handleSubmit}>
                          <div className="mb-5">
                            <InputIcon
                              type="text"
                              color="lightBlue"
                              placeholder="Nom"
                              name="lname"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <InputIcon
                              type="text"
                              color="lightBlue"
                              placeholder="Prénom"
                              name="fname"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <InputIcon
                              type="email"
                              color="lightBlue"
                              placeholder="Email"
                              name="email"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <InputIcon
                              type="text"
                              color="lightBlue"
                              placeholder="Numéro d'inscription (RPPS,Adeli,...)"
                              name="numCom"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-5 w-full">
                            <select
                              label="Select Version"
                              name="speciality"
                              onChange={handleChange}
                              className="form-select appearance-none  block w-full  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              aria-label="Default select example"
                            >
                              {ListSpecilaities.map((el) => (
                                <option value={el.value}> {el.label}</option>
                              ))}
                            </select>
                          </div>

                          <div className="mb-5 w-full">
                            <select
                              label="Select Version"
                              name="country"
                              onChange={handleChange}
                              className="form-select appearance-none  block w-full  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              aria-label="Default select example"
                            >
                              {CountryWithPhoneSuffix.map((el) => (
                                <option value={el.name}> {el.name}</option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-5">
                            <InputIcon
                              type="text"
                              color="lightBlue"
                              placeholder="Adresse professionnelle"
                              name="address"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <InputIcon
                              type="text"
                              color="lightBlue"
                              placeholder="Ville"
                              name="city"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <InputIcon
                              type="text"
                              color="lightBlue"
                              placeholder="Code postal"
                              name="postal_code"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <InputIcon
                              type="text"
                              color="lightBlue"
                              placeholder="Numéro de téléphone"
                              name="phone"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5 w-full">
                            <select
                              label="Select Version"
                              name="sexe"
                              onChange={handleChange}
                              className="form-select appearance-none  block w-full  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              aria-label="Sexe"
                            >
                              <option> Sexe</option>
                              <option value="women">Femme </option>
                              <option value="men"> Homme</option>
                            </select>
                          </div>
                        </form>
                      </div>

                      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModal(false)}
                        >
                          Annuler
                        </button>
                        {loading ? (
                          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-purple-500"></div>
                        ) : (
                          <button
                            className="bg-emerald-500 text-blue-500 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={handleSubmit}
                          >
                            Confirmer
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>

          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowModal(false);
            }}
          ></div>
        </>
      ) : null}
    </>
  );
}
