import React, { useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { useAtom } from "jotai";
import TimePicker from "react-time-picker";
import { v4 as uuidv4 } from "uuid";
/** API & HELPER */
import { setAgendaConfiguration } from "api/doctors";
/** COMPONENTS */
import makeToast from "components/Snackbar";
import { Button } from "@material-tailwind/react";

const duree = [
  { label: "15 min", value: "15" },
  { label: "30 min", value: "30" },
  { label: "45 min", value: "45" },
  { label: "1h", value: "60" },
];

const DAYS_IN_WEEK = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const TeleconsultationsForm = (props) => {
  const { doctor, setDoctor, selectedWorkLocation } = props;

  const [refreshConfigurations, setRefreshConfigurations] =
    React.useState(false);
  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const [selectedInput, setSelectedInput] = React.useState("");
  const [duration, setDuration] = React.useState(30);
  const [configurations, setConfigurations] = React.useState({
    [uuidv4()]: {
      start: "09:00",
      end: "18:00",
      days: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
    },
  });

  const submitTimeSlots = async () => {
    try {
      setLoadingBtn(true);
      const config = Object.values(configurations);
      await setAgendaConfiguration(
        {
          config,
          type: "teleconsultation",
          timeSlotDuration: duration,
          location: selectedWorkLocation,
          agendaId: doctor.agendaId,
          timeZone: doctor.timeZone,
        },
        doctor._id
      );
      makeToast(
        "success",
        "La configuration de votre agenda a été bien mise à jour"
      );

      setDoctor({
        ...doctor,
        AgendaConfigTeleconsultation: { config, timeSlotDuration: duration },
      });

      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      makeToast(
        "error",
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard"
      );
      console.error(error);
    }
  };

  useEffect(() => {
    let config = {};
    if (!_.isEmpty(doctor?.AgendaConfigTeleconsultation)) {
      doctor?.AgendaConfigTeleconsultation.config.map((el) => {
        config = {
          ...config,
          [uuidv4()]: {
            start: el.start,
            end: el.end,
            days: el.days,
          },
        };
      });
      setDuration(doctor?.AgendaConfigTeleconsultation?.timeSlotDuration);
      setConfigurations(config);
    }
    setRefreshConfigurations(!refreshConfigurations);
  }, []);

  //force refresh
  useEffect(() => {
    setConfigurations(configurations);
  }, [refreshConfigurations]);

  return (
    <div className="">
      {Object.keys(configurations).map((confItemKey) => {
        return (
          <div className="relative">
            <div className="flex justify-end mt-4">
              <div
                className=" rounded-lg bg-gray-200 bg-opacity-50 cursor-pointer px-2 py-1"
                onClick={() => {
                  let tempConfigurations = configurations;
                  delete tempConfigurations[confItemKey];
                  setConfigurations(tempConfigurations);
                  setRefreshConfigurations(!refreshConfigurations);
                }}
              >
                X
              </div>
            </div>
            <div className="workingDays flex justify-center items-center flex-wrap gap-4">
              {DAYS_IN_WEEK.map((day) => {
                return (
                  <div
                    className={classNames(
                      "day flex justify-center items-center cursor-pointer",
                      configurations[confItemKey].days.includes(day)
                        ? "active-box"
                        : "disabled-box"
                    )}
                    onClick={() =>
                      setConfigurations({
                        ...configurations,
                        [confItemKey]: {
                          ...configurations[confItemKey],
                          days: configurations[confItemKey].days.includes(day)
                            ? configurations[confItemKey].days.filter(
                                (d) => day !== d
                              )
                            : [...configurations[confItemKey].days, day],
                        },
                      })
                    }
                  >
                    <p>{day}</p>
                  </div>
                );
              })}
              {configurations[confItemKey].days.length < 7 ? (
                <Button
                  status="secondary"
                  width="222px"
                  height="40px"
                  // style={{ padding: "0 10px" }}
                  onClick={() => {
                    setConfigurations({
                      ...configurations,
                      [confItemKey]: {
                        ...configurations[confItemKey],
                        days: DAYS_IN_WEEK,
                      },
                    });
                  }}
                >
                  Réinitialiser la semaine
                </Button>
              ) : null}
            </div>
            <div className="plageHoraire flex justify-center items-center gap-x-2  ">
              <p>Plage de téléconsultation :</p>
              <div className="flex justify-center items-center">
                <div
                  className="flex justify-center items-center sub-values"
                  onClick={() => {
                    setSelectedInput(confItemKey + "-TemperatureTime");
                  }}
                >
                  {selectedInput === confItemKey + "-TemperatureTime" ? (
                    <TimePicker
                      onChange={(start) => {
                        if (start !== null)
                          setConfigurations({
                            ...configurations,
                            [confItemKey]: {
                              ...configurations[confItemKey],
                              start,
                            },
                          });
                      }}
                      format="HH:mm"
                      value={configurations[confItemKey].start}
                      onClockClose={() => {
                        setSelectedInput("");
                      }}
                      onClockOpen={() => {}}
                    />
                  ) : (
                    <p className="value flex justify-center items-center cursor-pointer">
                      {configurations[confItemKey].start !== 0
                        ? configurations[confItemKey].start
                        : "00:00"}
                    </p>
                  )}
                </div>
                <p>à :</p>
                <div
                  className="flex justify-center items-center sub-values"
                  onClick={() => {
                    setSelectedInput(confItemKey + "-TemperatureTime");
                  }}
                >
                  {selectedInput === confItemKey + "-TemperatureTime" ? (
                    <TimePicker
                      onChange={(end) => {
                        if (end !== null)
                          setConfigurations({
                            ...configurations,
                            [confItemKey]: {
                              ...configurations[confItemKey],
                              end,
                            },
                          });
                      }}
                      format="HH:mm"
                      value={configurations[confItemKey].end}
                      onClockClose={() => {
                        setSelectedInput("");
                      }}
                      onClockOpen={() => {}}
                    />
                  ) : (
                    <p className="value flex justify-center items-center cursor-pointer">
                      {configurations[confItemKey].end !== 0
                        ? configurations[confItemKey].end
                        : "00:00"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex justify-center items-center ">
        <div
          className="dashedButton flex justify-center items-center"
          onClick={() =>
            setConfigurations({
              ...configurations,
              [uuidv4()]: {
                start: "09:00",
                end: "18:00",
                days: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
              },
            })
          }
        >
          <p className="menu">Nouveau créneau de téléconsultation </p>
        </div>
      </div>

      <div className="rdvTimeContainer">
        <div className="flex justify-center items-center space-x-10 items-center w-full">
          <p>Les rendez-vous durent:</p>
          <select
            options={duree}
            text="Durée"
            className="ml-4"
            value={duration.toString(10)}
            onChange={(e) => setDuration(parseInt(e.target.value))}
          >
            {duree.map((el) => (
              <option value={el.value}>{el.label}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex justify-center items-center my-5">
        <Button
          status="primary"
          width="170px"
          height="50px"
          onClick={submitTimeSlots}
          disabled={loadingBtn}
        >
          <div className="relative">
            {loadingBtn && (
              <div className="absolute h-full w-full flex justify-center items-center">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            )}
            Enregistrer
          </div>
        </Button>
      </div>
    </div>
  );
};

export default TeleconsultationsForm;
