import React from "react";
import Card from "@material-tailwind/react/Card";
import { CardBody, CardHeader } from "@material-tailwind/react";
import UpdateLangue from "../Modals/Langue";

const Langue = ({ doctor, setDoctor }) => {
  return (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">LANGUES PARLÉES</h2>
          <UpdateLangue doctor={doctor} setDoctor={setDoctor} />
        </div>
      </CardHeader>
      <CardBody>
        <div className="paiements flex gap-10">
          {doctor?.langues &&
            doctor?.langues.map((langue) => (
              <div className="boxMethod flexRowCenter">
                <p className=" bg-purple-100 px-4 py-2 rounded-xl">{langue}</p>
              </div>
            ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default Langue;
