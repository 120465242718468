import React from "react";
import Card from "@material-tailwind/react/Card";
import SiteModal from "../Modals/site";
import { CardBody, CardHeader } from "@material-tailwind/react";
import { ReactComponent as Laptop } from "assets/icon/emoji-laptop.svg";
import { ReactComponent as Linkedin } from "assets/icon/emoji-linkedin.svg";
import { ReactComponent as Facebook } from "assets/icon/emoji-facebook.svg";
import { ReactComponent as Instagram } from "assets/icon/emoji-instagram.svg";
const Cursus = ({ doctor, setDoctor }) => {
  return (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">SITES & RÉSEAUX</h2>
          <SiteModal doctor={doctor} setDoctor={setDoctor} />
        </div>
      </CardHeader>
      <CardBody>
        <div className="containerSites">
          <div className="boxSite flex">
            <div className="svgContainer">
              <Laptop />
            </div>
            <p>{doctor?.website}</p>
          </div>
          <div className="boxSite flex">
            <div className="svgContainer">
              <Linkedin />
            </div>
            <p>{doctor?.linkedin}</p>
          </div>
          <div className="boxSite flex">
            <div className="svgContainer">
              <Facebook />
            </div>
            <p>{doctor?.facebook}</p>
          </div>
          <div className="boxSite flex">
            <div className="svgContainer">
              <Instagram />
            </div>
            <p>{doctor?.instagram}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Cursus;
