import React from "react";
import _ from "lodash";
import Card from "@material-tailwind/react/Card";
import ADDCursus from "../Modals/Cursus";
import { CardBody, CardHeader } from "@material-tailwind/react";
import { ReactComponent as Hat } from "assets/icon/icon-hat.svg";

const Cursus = ({ doctor, setDoctor }) => {
  return (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl ">CURSUS</h2>
          <ADDCursus doctor={doctor} setDoctor={setDoctor} />
        </div>
      </CardHeader>
      <CardBody>
        <div className="cursusContainer">
          {!_.isEmpty(doctor?.studiesHistory) ? (
            doctor?.studiesHistory.map((d, index) => {
              return (
                <div className="flex">
                  <div className="images">
                    <Hat />
                  </div>
                  <div className="details">
                    <div className="flex items-center space-x-2">
                      <p className="typeDiplome">{d.diplome}</p>
                      <span className="w-1 h-1 bg-black rounded"></span>
                      <p className="anneeDiplome">{d.year}</p>
                    </div>
                    <p className="university">{d.university}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="card-subtitle flex justify-center items-center mt-20">
              {" "}
              Vous n'avez pas encore ajouté votre cursus universitaire
            </p>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Cursus;
