import React from "react";
import Card from "@material-tailwind/react/Card";
import { CardBody, CardHeader } from "@material-tailwind/react";
import Expertise from "../Modals/Expertise";
import { updateDoctorById } from "api/doctors";

const expertise = ({ doctor, setDoctor }) => {
  const removeExpertise = async (expertise) => {
    const newUser = await updateDoctorById(
      {
        expertises: doctor?.expertises?.filter((el) => el._id !== expertise),
      },
      doctor?._id
    );
    const { expertises } = newUser;
    setDoctor({
      ...doctor,
      expertises,
    });
  };
  return (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">EXPERTISES</h2>
          <Expertise doctor={doctor} setDoctor={setDoctor} />
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex items-center flex-wrap  gap-2  pb-2">
          {doctor?.expertises &&
            doctor.expertises.map((expertise) => {
              return (
                <div className="relative bg-purple-100  px-4 py-2 rounded-xl">
                  <p>
                    {expertise.expertise}{" "}
                    <span
                      className="ml-2 cursor-pointer hover:text-red-500"
                      onClick={() => removeExpertise(expertise?._id)}
                    >
                      X
                    </span>
                  </p>
                </div>
              );
            })}
        </div>
      </CardBody>
    </Card>
  );
};

export default expertise;
