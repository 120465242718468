import React from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Checkbox from "@material-tailwind/react/Checkbox";
import Button from "@material-tailwind/react/Button";
import Page from "components/login/Page";
import { useAtom } from "jotai";
import Icon from "@material-tailwind/react/Icon";
import makeToast from "components/Snackbar";
import { userAtom } from "state/user";
import { login } from "api/auth";

export default function Login() {
  const [, setUser] = useAtom(userAtom);
  const [loading, setLoading] = React.useState(false);
  const [credentials, setCredentials] = React.useState({});
  const [clicked, setClicked] = React.useState(false);

  const handleChangeEnv = (env) => {
    if (localStorage.getItem("env") !== env) {
      localStorage.setItem("env", env);
      window.location.reload(true);
    } else setClicked(false);
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]:
        e.target.name === "rememberMe" ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { email, password, rememberMe } = credentials;
      const response = await login({ email, password, rememberMe });
      if (response.status === "success") {
        makeToast("success", "Vous êtes maintenant connecté");
      }
      rememberMe && localStorage.setItem("rememberMe", "true");
      setUser(response.user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      makeToast("error", " Votre email ou mot de passe sont incorrects.");
      console.error("[error] Signin - onSubmit :", error);
    }
  };
  return (
    <Page>
      {/* <div className="absolute inset-0">
        <img
          src={require("assets/img/bg-login.jpg").default}
          className="-z-50"
        />
      </div> */}
      <Card className="w-96 ">
        <CardHeader color="lightBlue">
          <H5 color="white" style={{ marginBottom: 0 }}>
            Se connecter
          </H5>
        </CardHeader>

        <CardBody>
          <div className="mb-12 px-4 bg-bb">
            <InputIcon
              type="email"
              color="lightBlue"
              placeholder="Email"
              iconName="email"
              name="email"
              onChange={handleChange}
              onKeyPress={(e) => {
                if (e.charCode === 13 || e.code === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </div>
          <div className="mb-8 px-4">
            <InputIcon
              type="password"
              color="lightBlue"
              placeholder="Mot de passe"
              iconName="lock"
              name="password"
              onChange={handleChange}
              onKeyPress={(e) => {
                if (e.charCode === 13 || e.code === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </div>
          <div className="mb-4 px-4">
            <Checkbox
              color="lightBlue"
              text="Se souvenir de moi "
              id="remember"
            />
          </div>
        </CardBody>
        <CardFooter>
          <div className="flex justify-center bg-bb ">
            {loading ? (
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-purple-500"></div>
            ) : (
              <Button
                color="lightBlue"
                size="lg"
                ripple="dark"
                onClick={handleSubmit}
              >
                Se connecter
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
      <div class="env mb-10 mt-10">
        <div
          class="env-btn uppercase  cursor-pointer"
          onClick={() => setClicked(!clicked)}
        >
          {localStorage.getItem("env") || "staging"}
        </div>
        <div
          class={
            clicked
              ? "clicked env-icon local cursor-pointer"
              : "env-icon local cursor-pointer"
          }
          onClick={() => handleChangeEnv("local")}
        >
          <Icon name="code" size="2xl" />
        </div>
        <div
          class={
            clicked
              ? "clicked env-icon staging cursor-pointer"
              : "env-icon staging cursor-pointer"
          }
          onClick={() => handleChangeEnv("staging")}
        >
          <Icon name="cloud_queue" size="2xl" />
        </div>

        <div
          class={
            clicked
              ? "clicked env-icon prod cursor-pointer"
              : "env-icon prod cursor-pointer"
          }
          onClick={() => handleChangeEnv("prod")}
        >
          {/* <Icon name="cloud_done" size="2xl" /> */}
          FR
        </div>

        <div
          class={
            clicked
              ? "clicked env-icon local cursor-pointer"
              : "env-icon local cursor-pointer"
          }
          onClick={() => handleChangeEnv("prodTN")}
        >
          TN
        </div>
      </div>
    </Page>
  );
}
