import axios from "axios";
import { ADD_BILL } from "../config/api";

export async function addBill(payload) {
  try {
    const res = await axios.post(`${ADD_BILL}`, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
