import { getAllJorunals } from "api/journalIns";
import JournalInsList from "components/JournalINS";
import React, { useEffect } from "react";

export const JouralIns = () => {
  const [journauxList, setJournausList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalResult, setTotalResult] = React.useState(0);
  const [limit] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const _getAllJournalIns = async () => {
    try {
      const response = await getAllJorunals(page, limit);
      setTotalResult(response?.data?.totalDocs);
      setTotalPage(response?.data?.totalPages);
      setPage(response?.data?.page);
      setJournausList(response?.data?.docs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    _getAllJournalIns();
  }, [page]);

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
          <JournalInsList
            journauxList={journauxList}
            page={page}
            search={search}
            setSearch={setSearch}
            setPage={setPage}
            totalPage={totalPage}
            totalResult={totalResult}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};
