import React from "react";
import { useAtom } from "jotai";
import { Icon } from "@material-tailwind/react";
import Spinner from "components/Spinner";
import { useParams } from "react-router-dom";
import { getDoctorById } from "api/doctors";
import Cabinet from "./Tabs/Cabinet";
import Tarif from "./Tabs/Tarif";
import Site from "./Tabs/Site";
import Expertise from "./Tabs/Expertise";
import Data from "./Tabs/Data";
import Langue from "./Tabs/Langue";
import Cursus from "./Tabs/Cursus";
import Upload from "./Tabs/Upload";
import Agenda from "./Tabs/Agenda";
import Bill from "./Tabs/Bill";
import { docctorAtom as DT } from "state/doctor";

export default function SettingsForm() {
  const [doctorAtom, setDoctorAtom] = useAtom(DT);
  const [loading, setLoading] = React.useState(false);
  const [tabs, setTabs] = React.useState(0);
  const [doctor, setDoctor] = React.useState();
  const { doctorId } = useParams();

  const _getDoctorById = async () => {
    try {
      setLoading(true);
      const response = await getDoctorById(doctorId);
      setDoctor(response);
      setDoctorAtom(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    _getDoctorById();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul
          className={`flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400`}
        >
          <li className="mr-2 cursor-pointer" onClick={() => setTabs(0)}>
            <span
              className={`inline-flex p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 0
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              <svg
                className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Informations générales
            </span>
          </li>
          <li className="mr-2 cursor-pointer" onClick={() => setTabs(8)}>
            <span
              className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 8
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              {" "}
              <Icon name="cloud_upload" />
              Téléchargement
            </span>
          </li>
          <li className="mr-2 cursor-pointer" onClick={() => setTabs(1)}>
            <span
              className={`inline-flex p-4 rounded-t-lg border-b-2  active dark:text-blue-500 dark:border-blue-500 group ${
                tabs === 1
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              <svg
                className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                <path
                  fill-rule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Cabinet
            </span>
          </li>
          <li className="mr-2 cursor-pointer" onClick={() => setTabs(2)}>
            <span
              className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 2
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              <Icon name="timeline" />
              Cursus
            </span>
          </li>

          <li className="mr-2 cursor-pointer" onClick={() => setTabs(3)}>
            <span
              className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 3
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              <Icon name="brightness_7" />
              Expertises
            </span>
          </li>

          <li className="mr-2 cursor-pointer" onClick={() => setTabs(4)}>
            <span
              className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 4
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              <Icon name="language" />
              Langues
            </span>
          </li>

          <li className="mr-2 cursor-pointer" onClick={() => setTabs(5)}>
            <span
              className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 5
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              <Icon name="attach_money" />
              tarif
            </span>
          </li>

          <li className="mr-2 cursor-pointer" onClick={() => setTabs(6)}>
            <span
              className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 6
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              {" "}
              <Icon name="account_circle" />
              Site
            </span>
          </li>

          <li className="mr-2 cursor-pointer" onClick={() => setTabs(7)}>
            <span
              className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                tabs === 7
                  ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                  : "border-transparent text-gray-500"
              }`}
            >
              {" "}
              <Icon name="event_available" />
              Agenda
            </span>
          </li>

          {(localStorage.getItem("env") == "prodTN" ||
            localStorage.getItem("env") == "local") && (
            <li className="mr-2 cursor-pointer" onClick={() => setTabs(9)}>
              <span
                className={`inline-flex gap-3 items-center p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                  tabs === 9
                    ? "border-blue-600 text-blue-600 dark:text-blue-500  dark:border-blue-500"
                    : "border-transparent text-gray-500"
                }`}
              >
                {" "}
                <Icon name="business" />
                Facture
              </span>
            </li>
          )}
        </ul>
      </div>
      {tabs === 0 && <Data doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 1 && <Cabinet doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 2 && <Cursus doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 3 && <Expertise doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 4 && <Langue doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 5 && <Tarif doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 6 && <Site doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 7 && <Agenda doctor={doctor} setDoctor={setDoctor} />}

      {tabs === 8 && <Upload doctor={doctor} setDoctor={setDoctor} />}

      {(localStorage.getItem("env") == "prodTN" ||
        localStorage.getItem("env") == "local") &&
        tabs === 9 && <Bill doctor={doctor} setDoctor={setDoctor} />}
    </>
  );
}
