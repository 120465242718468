import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "@material-tailwind/react/Button";
import { Formik } from "formik";
import _ from "lodash";
import { getAllPalier } from "api/palier";
import { addBill } from "api/bill";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import InputIcon from "@material-tailwind/react/InputIcon";
import { updateDoctorById } from "api/doctors";
import makeToast from "components/Snackbar";

export default function Cabinet({ doctor, setDoctor }) {
  const [showModal, setShowModal] = useState(false);
  const [listePalier, setListePalier] = useState([{ label: "Palier" }]);
  const handleOpen = (value) => setShowModal(!showModal);
  const onSubmitForm = async (values) => {
    try {
      let formData = new FormData();
      if (
        !values.reference ||
        !values.price ||
        !values.billableAt ||
        !values.palier ||
        !values.bill
      )
        return makeToast("warning", "Tous les champs sont obligatoires");
      formData.append("doc", values.bill);
      formData.append("doctorId", doctor._id);
      formData.append("id", uuidv4());
      formData.append("billableAt", values.billableAt);
      formData.append("price", values.price);
      formData.append("reference", values.reference);
      formData.append("palier", values.palier);

      const response = await addBill(formData);
      if (response?.doctor) {
        setDoctor({
          ...doctor,
          katomiTNBilled: response?.doctor?.katomiTNBilled,
        });
        makeToast("success", "Nouvelle Facture a été enregistrer avec succès");
        setShowModal(false);
      }
    } catch (err) {
      console.log("[error] L'ajout d'une facture", err);
    }
  };

  const _getAllPalier = async () => {
    try {
      const response = await getAllPalier();
      setListePalier(
        response?.palier?.map((el) => ({
          ...el,
          label: el.name,
          value: el._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    _getAllPalier();
  }, []);

  return (
    <>
      <h2 className="text-white text-md cursor-pointer" onClick={handleOpen}>
        Ajouter
      </h2>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto  overflow-y-auto ">
              {/*content*/}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                style={{
                  width: "700px",
                  // height: "800px",
                }}
              >
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">
                    Nouvelle facture
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex-auto cursusAdd ">
                  <Formik
                    initialValues={{
                      billableAt: "",
                      price: "",
                      reference: "",
                      palier: listePalier[0].value,
                      bill: null,
                    }}
                    onSubmit={() => {}}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      } = props;

                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="flex-auto pb-7 px-8 min-w-full mt-10"
                        >
                          <div className="flex flex-col w-full ">
                            <InputIcon
                              id="reference"
                              placeholder="Référence"
                              labelText="Référence"
                              className=""
                              value={values.reference}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />
                            <span className="my-4" />
                            <span className="text-black">Date de paiement</span>
                            <DatePicker
                              date={values.billableAt}
                              onDateChange={(date) =>
                                setFieldValue("billableAt", date)
                              }
                              locale={enGB}
                            >
                              {({ inputProps, focused }) => (
                                <input
                                  className={
                                    "input text-black border-0  outline-none focus:outline-none " +
                                    (focused ? " -focused" : "")
                                  }
                                  {...inputProps}
                                />
                              )}
                            </DatePicker>
                            <span className="my-4" />

                            <div className="mb-5 w-full">
                              <select
                                id="palier"
                                label="Select Version"
                                name="palier"
                                onChange={(e) =>
                                  setFieldValue("palier", e.target.value)
                                }
                                className="form-select appearance-none  block w-full   py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                aria-label="Default select example"
                              >
                                {listePalier.map((el) => (
                                  <option value={el.value}> {el.label}</option>
                                ))}
                              </select>
                            </div>

                            <span className="my-4" />
                            <InputIcon
                              id="price"
                              placeholder="prix"
                              labelText="prix"
                              className=""
                              value={values.price}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />
                            <span className="my-4" />

                            <div className="flex gap-2 relative w-full h-14 border-2 border-dashed ">
                              <input
                                id="bill"
                                placeholder=""
                                labelText=""
                                type="file"
                                className="w-full opacity-0 absolute inset-0 "
                                onChange={(e) => {
                                  setFieldValue("bill", e.target.files[0]);
                                }}
                                onBlur={handleBlur}
                                inlineLabel={false}
                                onfocus={true}
                              />
                              <span className="text-black text-center m-auto">
                                {values?.bill?.name
                                  ? values?.bill?.name
                                  : "Télécharger la facture"}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-between mt-10">
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className=" mb-4 btn-delete"
                              onClick={() => setShowModal(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className=" mb-4"
                              type="submit"
                              onClick={() => onSubmitForm(values)}
                            >
                              Enregistrer
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowModal(false);
            }}
          ></div>
        </>
      ) : null}
    </>
  );
}
