import axios from "axios";

switch (localStorage.getItem("env")) {
  case "local":
    axios.defaults.headers.common["sptenant"] =
      process.env.REACT_APP_KATOMI_TENANT;
    break;
  case "staging":
    axios.defaults.headers.common["sptenant"] =
      process.env.REACT_APP_KATOMI_TENANT;
    break;
  case "prod":
    axios.defaults.headers.common["sptenant"] = "katomi";
    break;
  case "prodTN":
    axios.defaults.headers.common["sptenant"] = "katomiTN";
    break;
  default:
    axios.defaults.headers.common["sptenant"] =
      process.env.REACT_APP_KATOMI_TENANT;
    break;
}
