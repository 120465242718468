import React from "react";
import { loadImage, deleteCard, deleteSignature } from "api/document";
import Signature from "../Modals/SignaturePic";
import ProfilePic from "../Modals/profilePic";
import CardProPicture from "../Modals/CardProPicture";

const Upload = ({ doctor, setDoctor }) => {
  const [cardProURI, setCardProURI] = React.useState("");
  const [profileURI, setProfileURI] = React.useState("");
  const [signatureURI, setSignatureURI] = React.useState("");
  const [modalConfirmDeleteCard, setModalConfirmDeleteCard] =
    React.useState(false);
  const [modalModifyCard, setModalModifyCard] = React.useState(false);
  const parseImage = (url, doctorId, type) => ({
    original: loadImage(doctorId, type, "lossless", url),
  });

  const hanldeDeleteSignature = async () => {
    try {
      await deleteSignature();
      setDoctor({ ...doctor, signaturePath: null });
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeDelete = async () => {
    try {
      await deleteCard();
      setDoctor({ ...doctor, proCard: null });
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (doctor.proCard)
      setCardProURI(parseImage(doctor.proCard, doctor._id, "proCard").original);
    if (doctor.business_photo)
      setProfileURI(
        parseImage(doctor.business_photo, doctor._id, "profile").original
      );
    if (doctor.signaturePath)
      setSignatureURI(
        parseImage(doctor.signaturePath, doctor._id, "signature").original
      );
  }, [doctor]);
  return (
    <>
      <div className="flex justify-around mt-16">
        <div className="w-1/4 text-center text-xl text-gray-600 font-bold mb-3">
          Carte professionnelle
        </div>
        <div className="w-1/4 text-center text-xl text-gray-600 font-bold mb-3">
          Phote de profil
        </div>
        <div className="w-1/4 text-center text-xl text-gray-600 font-bold mb-3">
          Signature{" "}
        </div>
      </div>
      <div className="flex justify-around ">
        <div className="w-1/4 border-dashed border border-blue-500 relative p-5 h-96">
          <CardProPicture
            doctor={doctor}
            setDoctor={setDoctor}
            cardProURI={cardProURI}
          />
        </div>

        <div className="w-1/4 border-dashed border border-blue-500 relative p-5 h-96">
          <ProfilePic
            doctor={doctor}
            setDoctor={setDoctor}
            profileURI={profileURI}
          />
        </div>
        <div className="w-1/4 border-dashed border border-blue-500 relative p-5 h-96">
          <Signature
            doctor={doctor}
            setDoctor={setDoctor}
            signatureURI={signatureURI}
          />
        </div>
      </div>
      {/* <Signature doctor={doctor} setDoctor={setDoctor} /> */}
    </>
  );
};

export default Upload;
