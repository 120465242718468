import React from "react";
import Doctor from "components/DoctorDetails";

const DoctorDetails = () => {
  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
          <Doctor />
        </div>
      </div>
    </>
  );
};

export default DoctorDetails;
