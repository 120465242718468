import axios from "axios";
import {
  DOCUMENTS_LOAD_ENDPOINT,
  ADD_SIGNATURE_DOCTOR,
  ADD_PHOTO_DOCTOR,
  ADD_CARDPRO_DOCTOR,
} from "../config/api";

export const loadImage = (userId, category, resolution, fileName) =>
  `${DOCUMENTS_LOAD_ENDPOINT}/load/${userId}/${category}/${resolution}/${fileName}?${Date.now()}`;

export const loadBill = (userId, fileName) =>
  `${DOCUMENTS_LOAD_ENDPOINT}/loadbill/${userId}/${fileName}?${Date.now()}`;

export const deleteCard = async () => {
  try {
    const res = await axios.delete("REMOVE_DOCTURE_CARDPRO");
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSignature = async () => {
  try {
    const res = await axios.delete("REMOVE_DOCTURE_SIGNATURE");
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteProfilePicture = async () => {
  try {
    const res = await axios.delete("REMOVE_DOCTURE_PROFILE_PICTURE");
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addSigntureToDoctor = async (values) => {
  try {
    const { file, doctorId } = values;
    let formData = new FormData();
    formData.append("doc", file);
    formData.append("doctorId", doctorId);
    const res = await axios.post(ADD_SIGNATURE_DOCTOR, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addProfilePhotoToDoctor = async (values) => {
  try {
    const { file, doctorId } = values;
    let formData = new FormData();
    formData.append("doc", file);
    formData.append("doctorId", doctorId);
    const res = await axios.post(ADD_PHOTO_DOCTOR, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addcardProToDoctor = async (values) => {
  try {
    const { file, doctorId } = values;
    let formData = new FormData();
    formData.append("doc", file);
    formData.append("doctorId", doctorId);
    const res = await axios.post(ADD_CARDPRO_DOCTOR, formData, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
