import React from "react";
import _ from "lodash";
import { useAtom } from "jotai";
import Spinner from "./components/Spinner";
import { userAtom } from "./state/user";
import { verify } from "./api/auth";
import Route from "./routes";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";

function App() {
  const [loading, setlLading] = React.useState(true);
  const [user, setUser] = useAtom(userAtom);

  React.useEffect(() => {
    setlLading(true);
    verify()
      .then((res) => {
        setUser(res.user);
        setlLading(false);
      })
      .catch((_) => {
        setlLading(false);
      });
  }, [setUser]);

  React.useEffect(() => {
    if (!localStorage.getItem("env")) localStorage.setItem("env", "staging");
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <Route status={_.isEmpty(user) ? "GUEST" : "ADMIN"} />
  );
}

export default App;
