import axios from "axios";

import {
    GET_ALL_LOGS,
    DOWNLOAD_LOGS_INS
} from "../config/api";

export async function getAllJorunals(page, limit) {
  try {
    const res = await axios.get(`${GET_ALL_LOGS}?limit=${limit}&page=${page}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}                                   

export async function downloadExcelLogs() {
  try {
    const res = await axios.get(DOWNLOAD_LOGS_INS, {
      withCredentials: true,
      responseType:"blob"
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}                                   