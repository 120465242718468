import { useState } from "react";
import Button from "@material-tailwind/react/Button";
import { FieldArray, Formik } from "formik";
import _ from "lodash";
import currencyList from "config/constants/currency";
import InputIcon from "@material-tailwind/react/InputIcon";
import { updateDoctorById } from "api/doctors";
import { addPalier, updatePalier } from "api/palier";
import makeToast from "components/Snackbar";

const Color = ["#ffd861", "", "#a26bfa", "#43ef8b"];

const emptyPalier = {
  _id: null,
  name: "",
  maxPatient: "",
  maxSMS: "",
  priceMonth: "",
  priceYear: "",
  currency: "",
};

export default function Cabinet({ palier, setPalier, refreshPalier }) {
  const [showModal, setShowModal] = useState(false);
  const handleOpen = (value) => setShowModal(!showModal);

  const onSubmitForm = async (values) => {
    try {
      console.log("debugger", values);
      setShowModal(!showModal);

      /**
       * update or add palier
       */
      await Promise.all(
        values.palier.map(async (palier) => {
          console.log(palier);
          if (palier._id) await updatePalier(palier, palier._id);
          else await addPalier(palier);
        })
      );

      refreshPalier();

      makeToast(
        "success",
        "La mise à jour des paliers a été effectuée avec succès"
      );
    } catch (err) {
      console.log("[error] La mise à jour des Paliers  -on Submit :", err);
    }
  };

  return (
    <>
      <h2 className="text-white text-md cursor-pointer" onClick={handleOpen}>
        Ajouter/Modifier
      </h2>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto  overflow-y-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">Palier</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto cursusAdd">
                  <Formik
                    initialValues={{
                      palier: _.isEmpty(palier) ? [emptyPalier] : palier,
                    }}
                    onSubmit={() => {}}
                    //validationSchema={cursusSchema}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      } = props;

                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="flex-auto pb-7 px-8 min-w-full"
                        >
                          <div className="flexCol w-full ">
                            <FieldArray name="palier">
                              {({ push, remove }) => (
                                <div className="flex gap-10">
                                  {values.palier.map((loc, index) => (
                                    <div className="flex flex-col gap-6">
                                      <InputIcon
                                        id={`palier.${index}.name`}
                                        placeholder={`Nom du palier`}
                                        labelText={`Nom du palier`}
                                        type="text"
                                        value={values.palier[index].name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`palier.${index}.maxPatient`}
                                        placeholder={`Nombre des patients`}
                                        labelText={`Nombre des patients`}
                                        type="text"
                                        value={values.palier[index].maxPatient}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`palier.${index}.maxSMS`}
                                        placeholder={`Nombre des SMS`}
                                        labelText={`Nombre des SMS`}
                                        type="text"
                                        value={values.palier[index].maxSMS}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`palier.${index}.priceMonth`}
                                        placeholder={`Prix mois`}
                                        labelText={`Prix mois`}
                                        type="text"
                                        value={values.palier[index].priceMonth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`palier.${index}.priceYear`}
                                        placeholder={`Prix année`}
                                        labelText={`Prix année`}
                                        type="text"
                                        value={values.palier[index].priceYear}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />

                                      <div className="mb-5 w-full">
                                        <select
                                          id={`palier.${index}.currency`}
                                          label="currency"
                                          value={values.palier[index].currency}
                                          onChange={handleChange}
                                          className="form-select appearance-none  block w-full  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        >
                                          {currencyList.map((el) => (
                                            <option value={el.code}>
                                              {`${el.code} - ${el.name}`}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="buttons flex justify-center items-center">
                                    {/* <Button
                                disabled={isSubmitting}
                                onClick={() => remove(index)}
                                status="secondary"
                              >
                                -
                              </Button> */}
                                    <Button
                                      type="button"
                                      width="45px"
                                      height="45px"
                                      onClick={() => push(emptyPalier)}
                                      status="secondary"
                                      className="mb-5 md:mb-0"
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                          </div>
                          <Button
                            status="secondary"
                            width="100px"
                            height="50px"
                            className="float-left mb-4 btn-delete"
                            onClick={() => setShowModal(false)}
                          >
                            Annuler
                          </Button>
                          <Button
                            status="secondary"
                            width="100px"
                            height="50px"
                            className="float-right mb-4"
                            type="submit"
                            disabled={isSubmitting}
                            onClick={() => onSubmitForm(values)}
                          >
                            Enregistrer
                          </Button>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowModal(false);
            }}
          ></div>
        </>
      ) : null}
    </>
  );
}
