import makeToast from "components/Snackbar";
import React from "react";
import { FieldArray, Formik } from "formik";
import Button from "@material-tailwind/react/Button";
import { Input } from "@material-tailwind/react";
import { updateDoctorById } from "api/doctors";
export default function Cursus({ doctor, setDoctor }) {
  const [showModal, setShowModal] = React.useState(false);
  const [newUserCursus, setNewUserCursus] = React.useState(
    doctor?.studiesHistory || []
  );
  const emptyCursus = { diplome: "", university: "", year: "" };
  React.useEffect(() => {
    if (doctor?.studiesHistory) setNewUserCursus(doctor?.studiesHistory);
  }, [doctor]);

  return (
    <>
      <h2
        className="text-white text-md cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        Ajouter
      </h2>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">
                    Ajouter Langue
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <Formik
                    initialValues={{
                      langues: doctor?.langues || [],
                    }}
                    onSubmit={async (values) => {
                      try {
                        const updatedDoctor = await updateDoctorById(
                          { langues: values.langues },
                          doctor?._id
                        );
                        const { langues } = updatedDoctor;
                        setDoctor({
                          ...doctor,
                          langues,
                        });
                        setShowModal(!showModal);
                        if (updatedDoctor._id) {
                          makeToast("success", "Langues modifiée avec succès.");
                        } else {
                          makeToast("error", "Ajout langues a échouhé.");
                        }
                      } catch (err) {
                        console.log("[error] add Patient -on Submit :", err);
                      }
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      } = props;

                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="flex-auto pb-7 px-8 min-w-full	"
                        >
                          <div className="flex flex-wrap items-baseline">
                            <h1 className="">Ajouter une langue</h1>
                          </div>
                          <div className="ml-8 mb-8">
                            <div class="form-check">
                              <input
                                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value="Français"
                                onChange={(e) => {
                                  setFieldValue(
                                    "langues",
                                    values.langues.includes("Français")
                                      ? values.langues.filter(
                                          (el) => el !== "Français"
                                        )
                                      : values.langues.concat("Français")
                                  );
                                }}
                                checked={
                                  values.langues.find((e) => e === "Français")
                                    ? true
                                    : false
                                }
                              />
                              <label
                                class="form-check-label inline-block text-gray-800"
                                for="flexCheckDefault"
                              >
                                Français
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value="Anglais"
                                onChange={(e) => {
                                  setFieldValue(
                                    "langues",
                                    values.langues.includes("Anglais")
                                      ? values.langues.filter(
                                          (el) => el !== "Anglais"
                                        )
                                      : values.langues.concat("Anglais")
                                  );
                                }}
                                checked={
                                  values.langues.find((e) => e === "Anglais")
                                    ? true
                                    : false
                                }
                              />
                              <label
                                class="form-check-label inline-block text-gray-800"
                                for="flexCheckDefault"
                              >
                                Anglais
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value="Allemand"
                                onChange={(e) => {
                                  setFieldValue(
                                    "langues",
                                    values.langues.includes("Allemand")
                                      ? values.langues.filter(
                                          (el) => el !== "Allemand"
                                        )
                                      : values.langues.concat("Allemand")
                                  );
                                }}
                                checked={
                                  values.langues.find((e) => e === "Allemand")
                                    ? true
                                    : false
                                }
                              />
                              <label
                                class="form-check-label inline-block text-gray-800"
                                for="flexCheckDefault"
                              >
                                Allemand
                              </label>
                            </div>

                            <div class="form-check">
                              <input
                                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value="Espagnol"
                                onChange={(e) => {
                                  setFieldValue(
                                    "langues",
                                    values.langues.includes("Espagnol")
                                      ? values.langues.filter(
                                          (el) => el !== "Espagnol"
                                        )
                                      : values.langues.concat("Espagnol")
                                  );
                                }}
                                checked={
                                  values.langues.find((e) => e === "Espagnol")
                                    ? true
                                    : false
                                }
                              />
                              <label
                                class="form-check-label inline-block text-gray-800"
                                for="flexCheckDefault"
                              >
                                Espagnol
                              </label>
                            </div>

                            <div class="form-check">
                              <input
                                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value="Arabe"
                                onChange={(e) => {
                                  setFieldValue(
                                    "langues",
                                    values.langues.includes("Arabe")
                                      ? values.langues.filter(
                                          (el) => el !== "Arabe"
                                        )
                                      : values.langues.concat("Arabe")
                                  );
                                }}
                                checked={
                                  values.langues.find((e) => e === "Arabe")
                                    ? true
                                    : false
                                }
                              />
                              <label
                                class="form-check-label inline-block text-gray-800"
                                for="flexCheckDefault"
                              >
                                Arabe
                              </label>
                            </div>

                            <div class="form-check">
                              <input
                                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value="Russe"
                                onChange={(e) => {
                                  setFieldValue(
                                    "langues",
                                    values.langues.includes("Russe")
                                      ? values.langues.filter(
                                          (el) => el !== "Russe"
                                        )
                                      : values.langues.concat("Russe")
                                  );
                                }}
                                checked={
                                  values.langues.find((e) => e === "Russe")
                                    ? true
                                    : false
                                }
                              />
                              <label
                                class="form-check-label inline-block text-gray-800"
                                for="flexCheckDefault"
                              >
                                Russe
                              </label>
                            </div>
                          </div>
                          <div className="flex justify-center items-center gap-10">
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className="float-left mb-4 btn-delete"
                              onClick={() => setShowModal(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className="float-right mb-4"
                            >
                              Enregistrer
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
