import React from "react";
import { CardBody, CardHeader, Card } from "@material-tailwind/react";
import currencyList from "config/constants/currency";
import EditPlaier from "components/EditPalier";
import { getAllPalier, deletePalier } from "api/palier";
import _ from "lodash";

const Palier = () => {
  const [palier, setPalier] = React.useState([]);

  const _getAllPalier = async () => {
    try {
      const response = await getAllPalier();
      setPalier(response?.palier);
    } catch (error) {
      console.log(error);
    }
  };

  const _deletePalier = async (palierId) => {
    try {
      await deletePalier(palierId);
      const newPalierList = palier.filter((el) => el._id != palierId);
      setPalier(newPalierList);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    _getAllPalier();
  }, []);

  //   basic
  // Premium
  // Ultimate
  return (
    <>
      <Card className="mt-20">
        <CardHeader color="purple" contentPosition="none">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-white text-2xl ">Palier</h2>
            <EditPlaier
              palier={palier}
              setPalier={setPalier}
              refreshPalier={() => _getAllPalier()}
            />
          </div>
        </CardHeader>
        <CardBody>
          <div class="pricing-wrapper flex justify-center">
            {_.isEmpty(palier) ? (
              <h1 className="m-auto text-xl font-bold">
                Listes des paliers vide
              </h1>
            ) : (
              palier.map((el, i) => (
                <div class={`pricing-table ${i % 2 && "recommended"}`}>
                  <h3 class="pricing-title">{el.name}</h3>
                  <div class="price">
                    {el.priceMonth}{" "}
                    {
                      currencyList.find(
                        (currency) => currency.code == el.currency
                      )?.code
                    }
                    <sup>/ mois</sup>
                  </div>

                  <ul class="table-list">
                    <li>{el.maxPatient} Patient</li>
                    <li>{el.maxSMS} SMS</li>
                  </ul>

                  <div class="table-buy">
                    <p>
                      {el.priceYear}
                      <sup>/ Année</sup>
                    </p>
                    <span
                      class="pricing-action"
                      onClick={() => _deletePalier(el._id)}
                    >
                      Supprimer
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Palier;
