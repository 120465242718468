import React from "react";
import _ from "lodash";
import Card from "@material-tailwind/react/Card";
import { CardBody, CardHeader } from "@material-tailwind/react";
import makeToast from "components/Snackbar";
import Presentiel from "./Presentiel";
import TeleconsultationsForm from "./Teleconsultation";
import Acte from "./Acte";

const Agenda = ({ doctor, setDoctor }) => {
  const [activeWorkLocation, setActiveWorkLocation] = React.useState(0);
  const [openTabSecondary, setOpenTabSecondary] = React.useState(1);

  const [selectedWorkLocation, setSelectedWorkLocation] = React.useState(
    doctor?.workingLocation[0]?.name
  );

  React.useEffect(() => {
    if (_.isEmpty(doctor?.workingLocation)) {
      makeToast("warning", "Merci d'avoir configurer votre agenda");
    }
  }, [doctor]);

  return _.isEmpty(doctor?.workingLocation) ? (
    <div className="flex justify-center items-center h-96">
      <h1 className="font-medium text-xl">
        Vous devez ajouter votre cabinet pour configurer l'agenda
      </h1>
    </div>
  ) : (
    <Card className="mt-20">
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">Agenda </h2>
        </div>
      </CardHeader>
      <CardBody className="agenda-config-form ">
        <h1 className="text-center mb-5">Plages horaires</h1>

        <div
          className="button-group-wrapper flex flex-row justify-center items-center max-w-max  "
          style={{ margin: "auto" }}
        >
          {!_.isEmpty(doctor.workingLocation) &&
            doctor.workingLocation.map((el, i) => (
              <button
                className={i === activeWorkLocation ? "active" : ""}
                type="button"
                onClick={() => {
                  setActiveWorkLocation(i);
                  setSelectedWorkLocation(doctor.workingLocation[i].name);
                }}
              >
                {el.name}
              </button>
            ))}
        </div>

        <div className="new-event-appointment-form  mt-10">
          <div
            className="button-group-wrapper flex flex-row justify-between items-center "
            style={{ margin: "auto" }}
          >
            <button
              type="button"
              className={openTabSecondary === 1 ? "active" : ""}
              onClick={() => setOpenTabSecondary(1)}
            >
              Présentiel
            </button>
            <button
              className={openTabSecondary === 2 ? "active" : ""}
              type="button"
              onClick={() => setOpenTabSecondary(2)}
            >
              Téléconsultation
            </button>
            <button
              type="button"
              className={openTabSecondary === 3 ? "active" : ""}
              onClick={() => setOpenTabSecondary(3)}
            >
              Actes
            </button>
          </div>
        </div>
        {openTabSecondary === 1 && (
          <Presentiel
            selectedWorkLocation={selectedWorkLocation}
            doctor={doctor}
            setDoctor={setDoctor}
          />
        )}
        {openTabSecondary === 2 && (
          <TeleconsultationsForm
            selectedWorkLocation={selectedWorkLocation}
            doctor={doctor}
            setDoctor={setDoctor}
          />
        )}
        {openTabSecondary === 3 && (
          <Acte
            doctor={doctor}
            setDoctor={setDoctor}
            selectedWorkLocation={selectedWorkLocation}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default Agenda;
