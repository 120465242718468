import { useState } from "react";
import Button from "@material-tailwind/react/Button";
import { FieldArray, Formik } from "formik";
import _ from "lodash";
import InputIcon from "@material-tailwind/react/InputIcon";
import { updateDoctorById } from "api/doctors";
import makeToast from "components/Snackbar";
import { Input } from "@material-tailwind/react";

const Expertise = ({ doctor, setDoctor }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <h2
        className="text-white text-md cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        Ajouter
      </h2>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">
                    Ajouter Expertise
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <Formik
                    initialValues={{
                      expertise: "",
                    }}
                    onSubmit={async (values) => {
                      try {
                        const newUser = await updateDoctorById(
                          {
                            expertises: doctor?.expertises?.concat(values) || [
                              values,
                            ],
                          },
                          doctor?._id
                        );
                        const { expertises } = newUser;
                        setDoctor({
                          ...doctor,
                          expertises,
                        });
                        setShowModal(false);
                        if (newUser._id) {
                          makeToast(
                            "success",
                            "Expertise ajoutée avec succès."
                          );
                        } else {
                          makeToast(
                            "error",
                            "L'ajout de l'expertise a échouhé."
                          );
                        }
                      } catch (err) {
                        setShowModal(false);
                        console.log("[error] add Patient -on Submit :", err);
                      }
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="flex-auto  px-8 min-w-full	"
                        >
                          <div className="flex flex-wrap items-baseline">
                            <h1 className="">Ajouter un expertise</h1>
                          </div>
                          <div className="mb-10">
                            <Input
                              id="expertise"
                              placeholder="Expertise ..."
                              //error={values.pricing}
                              value={values.expertise}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                              className="w-full "
                            />
                          </div>
                          <div className="flex justify-center items-center gap-10">
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className="float-left mb-4 btn-delete"
                              onClick={() => setShowModal(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className="float-right mb-4"
                              onClick={handleSubmit}
                            >
                              Enregistrer
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Expertise;
