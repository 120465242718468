import axios from "axios";

import {
  GET_ALL_PALIER,
  EDIT_PALIER,
  ADD_PALIER,
  DELETE_PALIER,
} from "../config/api";

export async function getAllPalier() {
  try {
    const res = await axios.get(GET_ALL_PALIER, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updatePalier(payload, palierId) {
  try {
    const res = await axios.post(`${EDIT_PALIER}/${palierId}`, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addPalier(payload) {
  try {
    const res = await axios.post(ADD_PALIER, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletePalier(palierId) {
  try {
    const res = await axios.post(
      `${DELETE_PALIER}/${palierId}`,
      {},
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
