import makeToast from "components/Snackbar";
import React from "react";
import { FieldArray, Formik } from "formik";
import Button from "@material-tailwind/react/Button";
import { Input } from "@material-tailwind/react";
import { updateDoctorById } from "api/doctors";
export default function Cursus({ doctor, setDoctor }) {
  const [showModal, setShowModal] = React.useState(false);
  const [newUserCursus, setNewUserCursus] = React.useState(
    doctor?.studiesHistory || []
  );
  const emptyCursus = { diplome: "", university: "", year: "" };
  React.useEffect(() => {
    if (doctor?.studiesHistory) setNewUserCursus(doctor?.studiesHistory);
  }, [doctor]);

  return (
    <>
      <h2
        className="text-white text-md cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        Ajouter
      </h2>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">
                    Ajouter Cursus
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <Formik
                    initialValues={{
                      cursus:
                        newUserCursus.length !== 0
                          ? newUserCursus
                          : [emptyCursus],
                    }}
                    onSubmit={async (values) => {
                      try {
                        setShowModal(!showModal);

                        const newUser = await updateDoctorById(
                          { studiesHistory: values.cursus },
                          doctor?._id
                        );

                        setDoctor({ ...doctor, ...newUser });
                        if (newUser._id) {
                          makeToast(
                            "success",
                            "Cursus universitaire ajouté avec succès."
                          );
                        } else {
                          makeToast(
                            "error",
                            "Ajout cursus universitaire a échouhé."
                          );
                        }
                      } catch (err) {
                        console.log("[error] add Patient -on Submit :", err);
                      }
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit} className="pb-5 px-5">
                          <h1 className="mb-10">Cursus universitaire</h1>

                          <FieldArray name="cursus">
                            {({ push, remove }) => (
                              <div>
                                {values.cursus.map((diploma, index) => (
                                  <div className="flex form-cursus flex-col md:flex-row mt-5 gap-5">
                                    <Input
                                      id={`cursus.${index}.diplome`}
                                      placeholder="Nom du diplôme"
                                      labelText="Nom du diplôme"
                                      type="text"
                                      // error={values.cursus[index].diplome}
                                      value={values.cursus[index].diplome}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      inlineLabel={false}
                                      onfocus={true}
                                    />

                                    <Input
                                      id={`cursus.${index}.university`}
                                      placeholder="Nom de l'université"
                                      labelText="Nom de l'université"
                                      type="text"
                                      // error={values.cursus[index].university}
                                      value={values.cursus[index].university}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      inlineLabel={false}
                                      onfocus={true}
                                    />
                                    <Input
                                      id={`cursus.${index}.year`}
                                      placeholder="Année universitaire"
                                      labelText="Année universitaire"
                                      type="text"
                                      // error={values.cursus[index].year}
                                      value={values.cursus[index].year}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      inlineLabel={false}
                                      onfocus={true}
                                    />
                                    <div className="buttons flex justify-center items-center gap-3">
                                      <Button
                                        disabled={isSubmitting}
                                        onClick={() => remove(index)}
                                        status="secondary"
                                      >
                                        -
                                      </Button>
                                      {values.cursus.length === index + 1 && (
                                        <Button
                                          disabled={isSubmitting}
                                          onClick={() => push(emptyCursus)}
                                          status="secondary"
                                        >
                                          +
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </FieldArray>

                          <div className="mt-10">
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className="float-left my-4 btn-delete "
                              onClick={() => setShowModal(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              status="secondary"
                              width="100px"
                              height="50px"
                              className="float-right mb-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Enregistrer
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
