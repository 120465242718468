const KATOMI_API_ENDPOINT = () => {
  switch (localStorage.getItem("env")) {
    case "local":
      return "http://localhost:8080/v1";
    case "staging":
      return "https://patientelo-api.katomi.co/v1";
    case "prod":
      return "https://api.kore.katomi.co/v1";
    case "prodTN":
      return "https://api.kore.katomi.co/v1";
    default:
      return "https://api.kore.katomi.co/v1";
  }
};

// export const KATOMI_API_ENDPOINT = `${process.env.REACT_APP_KATOMI_API_URL}v${process.env.REACT_APP_KATOMI_API_VERSION}`;
const KATOMI_ADMIN_ENDPOINT = `${KATOMI_API_ENDPOINT()}/admin`;

// AUTH SUPER ADMIN
export const LOGIN_URL = `${KATOMI_ADMIN_ENDPOINT}/login`;
export const VERIFY_URL = `${KATOMI_ADMIN_ENDPOINT}/verify`;
export const LOGOUT_URL = `${KATOMI_ADMIN_ENDPOINT}/logout`;

// DOCTOR MANAGEMENT
export const GET_ALL_DOCTORS_URL = `${KATOMI_ADMIN_ENDPOINT}/getAllDoctors`;
export const ADD_DOCTOR_URL = `${KATOMI_ADMIN_ENDPOINT}/addDoctor`;
export const GET_DOCTOR_BY_ID = `${KATOMI_ADMIN_ENDPOINT}/getDoctorById`;
export const UPDATE_DOCTOR = `${KATOMI_ADMIN_ENDPOINT}/updateDoctor`;
export const DOCTOR_SET_AGENDA_CONFIG = `${KATOMI_ADMIN_ENDPOINT}/agenda-config`;
export const ADD_SIGNATURE_DOCTOR = `${KATOMI_ADMIN_ENDPOINT}/addSigntureDoctor`;
export const ADD_PHOTO_DOCTOR = `${KATOMI_ADMIN_ENDPOINT}/addPhotoDoctor`;
export const REMOVE_DOCTURE_SIGNATURE = `${KATOMI_ADMIN_ENDPOINT}/remove-signature`;
export const REMOVE_DOCTURE_PROFILE_PICTURE = `${KATOMI_ADMIN_ENDPOINT}/removeDoctorProfilePicture`;
export const ADD_CARDPRO_DOCTOR = `${KATOMI_ADMIN_ENDPOINT}/add-cardProd`;
export const REMOVE_DOCTURE_CARDPRO = `${KATOMI_ADMIN_ENDPOINT}/removeCardPro`;

// DOCUMENTS ENDPOINTS
export const DOCUMENTS_LOAD_ENDPOINT = `${KATOMI_API_ENDPOINT()}/documents`;
export const ADD_BILL = `${DOCUMENTS_LOAD_ENDPOINT}/add-bill`;

// PALIER ENDPOINTS
export const GET_ALL_PALIER = `${KATOMI_API_ENDPOINT()}/palier`;
export const EDIT_PALIER = `${KATOMI_ADMIN_ENDPOINT}/updatePalier`;
export const ADD_PALIER = `${KATOMI_ADMIN_ENDPOINT}/addPalier`;
export const DELETE_PALIER = `${KATOMI_ADMIN_ENDPOINT}/deletePalier`;

// JOURNAL ENDPOINT
export const GET_ALL_JOURNAL = `${KATOMI_API_ENDPOINT()}/logsINS`;
export const GET_ALL_LOGS = `${GET_ALL_JOURNAL}/getAllLogs`;
export const DOWNLOAD_LOGS_INS = `${GET_ALL_JOURNAL}/downloadExcelLogs`;
