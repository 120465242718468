import { useState } from "react";
import { NavLink } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import Icon from "@material-tailwind/react/Icon";
import makeToast from "components/Snackbar";

export default function Sidebar() {
  const [showSidebar, setShowSidebar] = useState("-left-64");
  const [clicked, setClicked] = useState(false);

  const handleChangeEnv = (env) => {
    if (localStorage.getItem("env") !== env) {
      localStorage.setItem("env", env);
      window.location.reload(true);
    } else setClicked(false);
  };

  return (
    <>
      <AdminNavbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div
        className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
      >
        <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <span className="mt-2 text-center w-full inline-block">
            <img
              src={require("../assets/LOGO.jpg").default}
              width={150}
              className="m-auto"
              alt="logo"
            />
          </span>

          <div class="env mb-10">
            <div
              class="env-btn uppercase  cursor-pointer"
              onClick={() => setClicked(!clicked)}
            >
              {localStorage.getItem("env") || "staging"}
            </div>
            <div
              class={
                clicked
                  ? "clicked env-icon local cursor-pointer"
                  : "env-icon local cursor-pointer"
              }
              onClick={() => handleChangeEnv("local")}
            >
              <Icon name="code" size="2xl" />
            </div>
            <div
              class={
                clicked
                  ? "clicked env-icon staging cursor-pointer"
                  : "env-icon staging cursor-pointer"
              }
              onClick={() => handleChangeEnv("staging")}
            >
              <Icon name="cloud_queue" size="2xl" />
            </div>

            <div
              class={
                clicked
                  ? "clicked env-icon prod cursor-pointer"
                  : "env-icon prod cursor-pointer"
              }
              onClick={() => handleChangeEnv("prod")}
            >
              {/* <Icon name="cloud_done" size="2xl" /> */}
              FR
            </div>

            <div
              class={
                clicked
                  ? "clicked env-icon local cursor-pointer"
                  : "env-icon local cursor-pointer"
              }
              onClick={() => handleChangeEnv("prodTN")}
            >
              TN
            </div>
          </div>

          <div className="flex flex-col">
            <hr className="my-4 min-w-full" />

            <ul className="flex-col min-w-full flex list-none">
              <li className="rounded-lg mb-4">
                <NavLink
                  to="/"
                  exact
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="dashboard" size="2xl" />
                  Accueil
                </NavLink>
              </li>
              <li className="rounded-lg mb-4">
                <NavLink
                  to="/palier"
                  exact
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="business" size="2xl" />
                  Palier
                </NavLink>
              </li>
              <li className="rounded-lg mb-4">
                <NavLink
                  to="/journauxIns"
                  exact
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="business" size="2xl" />
                  Journaux INS
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
