import { useState } from "react";

import Button from "@material-tailwind/react/Button";
import { FieldArray, Formik } from "formik";
import _ from "lodash";
import InputIcon from "@material-tailwind/react/InputIcon";
import { updateDoctorById } from "api/doctors";
import makeToast from "components/Snackbar";

const emptyLocations = {
  location: "",
  name: "",
  type: "Cabinet",
  color: "#000",
  tel: "",
  postalCode: "",
  ville: "",
};

export default function Cabinet({ doctor, setDoctor }) {
  const [showModal, setShowModal] = useState(false);
  const handleOpen = (value) => setShowModal(!showModal);
  const onSubmitForm = async (values) => {
    try {
      setShowModal(!showModal);
      const newUser = await updateDoctorById(values, doctor?._id);
      setDoctor({ ...doctor, ...newUser });
      if (newUser._id) {
        makeToast(
          "success",
          "La mise à jour de vos consultations a été effectué avec succès"
        );
      } else {
        makeToast("error", "La mise à jour de vos consultations  a échouhé.");
      }
    } catch (err) {
      console.log(
        "[error] La mise à jour  de vos consultations  -on Submit :",
        err
      );
    }
  };

  return (
    <>
      <h2 className="text-white text-md cursor-pointer" onClick={handleOpen}>
        Modifier
      </h2>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto  overflow-y-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold  text-black">
                    Cabinet
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto cursusAdd">
                  <Formik
                    initialValues={{
                      workingLocation: _.isEmpty(doctor?.workingLocation)
                        ? [emptyLocations]
                        : doctor?.workingLocation,
                    }}
                    onSubmit={() => {}}
                    //validationSchema={cursusSchema}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      } = props;

                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="flex-auto pb-7 px-8 min-w-full"
                        >
                          <div className="flex flex-wrap items-baseline">
                            <h1 className="mb-10">Mes consultations</h1>
                          </div>
                          <div className="flexCol w-full">
                            <FieldArray name="workingLocation">
                              {({ push, remove }) => (
                                <div className="flex gap-10">
                                  {values.workingLocation.map((loc, index) => (
                                    <div className="flex flex-col gap-6">
                                      <InputIcon
                                        id={`workingLocation.${index}.name`}
                                        placeholder={`Nom du consultation ${
                                          index + 1
                                        }`}
                                        labelText={`Nom du consultation ${
                                          index + 1
                                        }`}
                                        type="text"
                                        // error={values.workingLocation[index].location}
                                        value={
                                          values.workingLocation[index].name
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`workingLocation.${index}.location`}
                                        placeholder={`Adresse du consultation ${
                                          index + 1
                                        }`}
                                        labelText={`Adresse du consultation ${
                                          index + 1
                                        }`}
                                        type="text"
                                        // error={values.workingLocation[index].location}
                                        value={
                                          values.workingLocation[index].location
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`workingLocation.${index}.ville`}
                                        placeholder={`Ville du consultation ${
                                          index + 1
                                        }`}
                                        labelText={`Ville du consultation ${
                                          index + 1
                                        }`}
                                        type="text"
                                        // error={values.workingLocation[index].location}
                                        value={
                                          values.workingLocation[index].ville
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`workingLocation.${index}.postalCode`}
                                        placeholder={`code postal du consultation ${
                                          index + 1
                                        }`}
                                        labelText={`code postal du consultation ${
                                          index + 1
                                        }`}
                                        type="text"
                                        // error={values.workingLocation[index].location}
                                        value={
                                          values.workingLocation[index]
                                            .postalCode
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />
                                      <InputIcon
                                        id={`workingLocation.${index}.tel`}
                                        placeholder={`téléphone du consultation ${
                                          index + 1
                                        }`}
                                        labelText={`téléphone du consultation ${
                                          index + 1
                                        }`}
                                        type="text"
                                        // error={values.workingLocation[index].location}
                                        value={
                                          values.workingLocation[index].tel
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inlineLabel={false}
                                        onfocus={true}
                                      />

                                      <select
                                        label="Type"
                                        name={`workingLocation.${index}.type`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-select appearance-none  block w-full  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border-b border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        aria-label="Default select example"
                                      >
                                        {[
                                          {
                                            value: "Cabinet",
                                            label: "Cabinet",
                                          },
                                          {
                                            value: "Clinique",
                                            label: "Clinique",
                                          },
                                          {
                                            value: "Hôpital",
                                            label: "Hôpital",
                                          },
                                        ].map((el) => (
                                          <option value={el.value}>
                                            {" "}
                                            {el.label}
                                          </option>
                                        ))}
                                      </select>
                                      <input
                                        id={`workingLocation.${index}.color`}
                                        type="color"
                                        name={`workingLocation.${index}.color`}
                                        className="md:ml-4 h-14"
                                        value={
                                          values.workingLocation[index].color
                                        }
                                        onChange={handleChange}
                                      />
                                    </div>
                                  ))}
                                  <div className="buttons flex justify-center items-center">
                                    {/* <Button
                                disabled={isSubmitting}
                                onClick={() => remove(index)}
                                status="secondary"
                              >
                                -
                              </Button> */}
                                    <Button
                                      type="button"
                                      width="45px"
                                      height="45px"
                                      onClick={() => push(emptyLocations)}
                                      status="secondary"
                                      className="mb-5 md:mb-0"
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                          </div>
                          <Button
                            status="secondary"
                            width="100px"
                            height="50px"
                            className="float-left mb-4 btn-delete"
                            onClick={() => setShowModal(false)}
                          >
                            Annuler
                          </Button>
                          <Button
                            status="secondary"
                            width="100px"
                            height="50px"
                            className="float-right mb-4"
                            type="submit"
                            disabled={isSubmitting}
                            onClick={() => onSubmitForm(values)}
                          >
                            Enregistrer
                          </Button>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowModal(false);
            }}
          ></div>
        </>
      ) : null}
    </>
  );
}
