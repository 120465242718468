import axios from "axios";

import {
  GET_ALL_DOCTORS_URL,
  ADD_DOCTOR_URL,
  GET_DOCTOR_BY_ID,
  UPDATE_DOCTOR,
  DOCTOR_SET_AGENDA_CONFIG,
} from "../config/api";

export const getAllDoctors = async (page, limit, filerObject) => {
  try {
    let filter = "";
    for (const property in filerObject) {
      if (filerObject[property])
        filter += `&${property}=${filerObject[property]}`;
    }
    const res = await axios.get(
      `${GET_ALL_DOCTORS_URL}?limit=${limit}&page=${page}${filter}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addDoctor = async (payload) => {
  try {
    const res = await axios.post(ADD_DOCTOR_URL, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDoctorById = async (doctorId) => {
  try {
    const res = await axios.get(`${GET_DOCTOR_BY_ID}/${doctorId}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDoctorById = async (payload, doctorId) => {
  try {
    const res = await axios.post(
      `${UPDATE_DOCTOR}/${doctorId}`,
      { payload },
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setAgendaConfiguration = async (payload, doctorId) => {
  try {
    const res = await axios.post(
      `${DOCTOR_SET_AGENDA_CONFIG}/${doctorId}`,
      payload,
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
